import cloneDeep from 'lodash-es/cloneDeep';

import { HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import {
    detailedDisplayDesignToProjectDesignC2C, IDetailedDisplayDesign as IDetailedDisplayDesignC2C
} from '@profis-engineering/pe-ui-c2c/entities/display-design';
import {
    ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import { ProjectType, ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DocumentGetDocument, DocumentGetProject, DocumentGetResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    CantArchiveProjectsBecauseDocumentInUse,
    CantDeleteProjectsBecauseDocumentInUse,
    CantOpenDesignBecauseLockedByOtherUser,
    CantPerformActionReasonProblematicDocumentInformation,
    CantRestoreProjectsBecauseDocumentInUse, DesignExternalMetaData as DesignExternalMetaDataCommon,
    DocumentAccessMode, IDefferedData as IDefferedDataCommon,
    IDesignListItem as IDesignListItemCommon, IDocumentServiceDesign
} from '@profis-engineering/pe-ui-common/services/document.common';
import {
    detailedDisplayDesignToProjectDesign, IDetailedDisplayDesign as IDetailedDisplayDesignPe
} from '@profis-engineering/pe-ui-shared/entities/display-design';
import {
    ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { environment } from '../../environments/environment';
import { Design, IBaseDesign } from '../entities/design';
import { DesignExternalMetaData } from '../entities/design-external-meta-data';
import { IProjectArchive, Project } from '../entities/project';
import { IDefferedData, IDefferedDataC2C, IDefferedImageData, IDefferedRequests } from '../helpers/document-helper';
import { urlPath } from '../module-constants';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { DocumentService, IDesignListItem } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { ModulesDocumentService } from './modules-document.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';

export interface IGetDocumentResponse {
    documentid: string;
    project: IGetDocumentResponseProject;
    name: string;
    lastchange: number;
    created: number;
    locked: boolean;
    lockedby: string;
    metadata: { key: string, value: string }[];
    owner: boolean;
}

export interface IGetDocumentResponseProject {
    projectid: string;
    parentProjectId: string;
    name: string;
}

export class DesingSessionPair {
    /*
    * design Id
    */
    public designId: string;

    /*
    * Session id of the design.
    */
    public sessionId: string;

    /*
    * This session was explicitly cancelled.
    */
    public isCancelled = false;
}


export abstract class DocumentWebCommon extends DocumentService {
    protected defferedRequests: IDefferedRequests<any, any> = {};
    protected defferedImageUpload: IDefferedRequests<any, any> = {};

    protected internalRawProject: DocumentGetResponse;
    protected documentThumbnails: Record<string, string> = {};
    protected documentThumbnailPromise: Record<string, Promise<unknown>> = {};

    // holds instances of all open sessions (should be just one really).
    protected designSessionMapping: DesingSessionPair[] = [];


    constructor(
        serviceName: string,
        loggerService: LoggerService,
        modulesDocumentService: ModulesDocumentService,
        protected routingService: RoutingService,
        protected apiService: ApiService,
        protected browserService: BrowserService,
        protected modalService: ModalService,
        protected localizationService: LocalizationService,
        protected guidService: GuidService,
        protected userService: UserService,
        protected errorHandlerService: ErrorHandlerService
    ) {
        super(serviceName, loggerService, modulesDocumentService);
    }


    public initializeCommon(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[]): void {
        this.internalRawProject = { projects: {}, documents: {} };
        this.getInternal(data);
        this.getArchiveInternal(dataArchive);
    }

    /**
     * Creates a unique file name from a given name
     */
    public createUniqueName(oldName: string, usedNames: string[]): string {
        let newName = oldName;
        let index = 0;

        while (usedNames.find((item) => item == newName) != null) {
            index++;
            newName = oldName + ' (' + index + ')';
        }

        return newName;
    }

    public async updateDesignProject(designId: string, projectId: string): Promise<void> {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        return this.openDesignExclusivePe(design)
            .then((projectDesign) => {
                projectDesign.ProjectName = project.name;

                return this.updateDesignWithNewContent(design.id, project.id, design.designName, projectDesign, null, null, true, false, DocumentAccessMode.Update);
            })
            .then(() => {
                this.updateProject(designId, projectId);
            });
    }

    /**
     * Updates a design with the new project design.
     * @param design - The design entity.
     */
    public async updateDesign(design: Design): Promise<void> {
        const metaData: DesignExternalMetaData = DesignExternalMetaData.getMetaDataFromDesign(design);

        if (design) {
            if (design.isC2C) {
                await this.updateDesignWithNewContentC2C(design.id, design.projectId, design.designName, design.designData.projectDesignC2C, metaData, null, false, false, DocumentAccessMode.Update);
            }
            else {
                await this.updateDesignWithNewContent(design.id, design.projectId, design.designName, design.designData.projectDesign, metaData, null, false, false, DocumentAccessMode.Update);
            }
        }
    }

    /**
     * Update design thumbnail image.
     * @param designId Design id
     * @param thumbnailContent Thumbnail image
     * @param respond If server should send response
     */
    public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedImageUpload[designId] == null || (this.defferedImageUpload[designId] != null && this.defferedImageUpload[designId].running == null)) {
            this.defferedImageUpload[designId] = {
                running: this.updateDesignThumbnailImagePromise({ designId, thumbnailContent, respond } as IDefferedImageData),
                defferedData: null,
                deffered: null
            };

            await this.defferedImageUpload[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedImageUpload[designId].deffered == null) {
                this.defferedImageUpload[designId].deffered = new Deferred();
            }

            this.defferedImageUpload[designId].defferedData = {
                designId,
                thumbnailContent,
                respond,
                isTemplate: false
            };

            await this.defferedImageUpload[designId].deffered.promise;
        }
    }

    /**
     * Deletes (permanently) the designs from internal store and call external document service.
     * @designIds list of designs
     */
    public async removeDesigns(designIds: string[]): Promise<void> {

        for (const designId of designIds) {
            const dToDeleteCandidate = this.findDesignById(designId);
            if (dToDeleteCandidate == null) {
                throw new Error(`Design with ID ${designId} does not exist.`);
            }
        }

        // delete designs
        await this.removeExistingDesigns(designIds);

        for (const designId of designIds) {
            this.deleteDesignFunc(designId);
        }
    }

    /**
     * Upload design printscreen image when a design report is ready for export
     * @param designId - design id
     * @param imageContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        return this.uploadDesignImageInternal(designId, imageContent, thumbnailContent);
    }

    /**
     * Publishes the design.
     * @param id - The design id.
     */
    public async publish(id: string): Promise<void> {
        // TODO: should check session here

        if (!this.isCanceledSessionForDesign(id)) {
            await this.postUnlockDocument(id);
            this.closeSessionForDesign(id);
        }
    }

    /**
     * Returns users on the project
     * @param projectId - Project id
     */
    public async getUsersOnProjectById(projectId: string): Promise<ProjectUser[]> {
        const response = await this.getUsersOnProjectByIdInternal(projectId);

        return response.map((item) => {
            item.projectid = projectId;
            item.dateadded = item.dateadded != null ? new Date(item.dateadded as any) : item.dateadded;
            return item;
        });
    }

    /**
     * Adds user to the project
     * @param data - Project user data
     */
    public addUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.addUsersOnProjectByIdInternal(data);
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    public removeUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.removeUsersOnProjectByIdInternal(data);
    }

    public userHasAccessToProject(projectId: string): Promise<boolean> {
        return this.userHasAccessToProjectInternal(projectId);
    }

    /**
     * Find design by name.
     * @param designName - Design name
     * @param projectId - Project id
     */
    public async findDesignByName(designName: string, projectId: string, forceFromServer?: boolean): Promise<IDesignListItemCommon> {
        return this.findDesignByNameInternal(designName, projectId, forceFromServer);
    }

    public openNewSessionForDesign(designId: string): string {
        // just return new design if already exists, where we have certain methods where it is not certain if session is allready open
        if (this.designSessionMapping.some(m => m.designId == designId)) {
            return this.getSessionKeyForDesign(designId);
        }

        // create new session
        const key = this.guidService.new();

        this.designSessionMapping.push({ designId, sessionId: key, isCancelled: false });

        return key;
    }

    public getSessionKeyForDesign(designId: string): string {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            throw new Error('Session is not opened for the specified design.');
        }

        return mapping.sessionId;
    }

    public getNumberOfDocumentsOwnedByUser(): number {
        return Object.values(this._desingsFlat).filter(x => x.owner).length;
    }

    public async updateDocumentDesignContent(document: IDesignListItem, base64Content: string,  unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent`;

        const rData = {
            key: this.getSessionKeyForDesign(document.id),
            documentid: document.id,
            projectid: document.projectId,
            name: document.designName,
            filecontent: base64Content,
            unlock,
            exclusiveLock,
            documentAccessMode
        };

        const request = new HttpRequest('PUT', url, rData, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request);
        }
        catch (response) {
            console.error(response);
        }
    }



    public async openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        try {
            return await this.getDesignContentExclusive<TProjectDesign>(design.id, this.openNewSessionForDesign(design.id), undefined, adjustContent);
        }
        catch (response) {
            await this.catchDesignExclusive(response, design);

            throw response;
        }
    }

    /**
     * Takes the design id file and returns JSON object, representing it.
     * In some cases object deriving from CantOpenDesignReason is returned.
     * @param design - Design
     */
    public async openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.openDesignExclusive<ProjectDesignBaseEntity>(design, (content, designName, projectName) => {
            content.DesignName = designName;
            content.ProjectName = projectName;
            return content;
        });
    }

    public async openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return this.openDesignExclusive<ProjectDesignEntityC2C>(design, (content, designName, projectName) => {
            content.designName = designName;
            content.projectName = projectName;
            return content;
        });
    }

    public async openDesignExclusiveDecking(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.openDesignExclusive<ProjectDesignBaseEntity>(design);
    }


    public async openDesign(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        const response = this.getContent<ProjectDesignBaseEntity>(design.id);
        this.openNewSessionForDesign(design.id);

        return response;
    }

    public async openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        const response = await this.getContent<ProjectDesignEntityC2C>(design.id);
        this.openNewSessionForDesign(design.id);

        return response;
    }


    /**
     * Updates a design with the new project design. Instead of using the ProjectDesignBaseEntity in design file use the ProjectDesignBaseEntity in the override parameter.
     * @param design - The design entity.
     */
    public async updateDesignWithNewContentCommon<TDisplayDesign>(
        design: DesignCommon,
        displayDesign: TDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        const defferedData: IDefferedDataCommon<any, TDisplayDesign> = {
            designId: design.id,
            projectId: design.projectId,
            designName: design.designName,
            contentOverride: design.getDocumentDesign ? design.getDocumentDesign() : design.projectDesign,
            metadataOverride: design.metaData,
            unlock,
            displayDesign,
            exclusiveLock,
            documentAccessMode
        };
        await this.updateDesignWithNewContentInternal(
            design.id,
            defferedData,
            this.putSmallDesignChangePromiseCommon.bind(this)
        );
    }

    public async updateDesignWithNewContent(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignBaseEntity,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignPe,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        const defferedData: IDefferedData = {
            designId,
            projectId,
            designName,
            contentOverride,
            metadataOverride,
            unlock,
            displayDesign,
            exclusiveLock,
            documentAccessMode
        };
        await this.updateDesignWithNewContentInternal(
            designId,
            defferedData,
            this.putSmallDesignChangePromise.bind(this)
        );
    }

    public async updateDesignWithNewContentC2C(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignEntityC2C,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignC2C,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        const defferedData: IDefferedDataC2C = {
            designId,
            projectId,
            designName,
            contentOverride,
            metadataOverride,
            unlock,
            displayDesign,
            exclusiveLock,
            documentAccessMode
        };
        await this.updateDesignWithNewContentInternal(
            designId,
            defferedData,
            this.putSmallDesignChangePromiseC2C.bind(this)
        );
    }


    public async smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(
        internalDesign: IDesignListItemCommon,
        projectDesign: TProjectDesign,
        data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>,
        metaData: DesignExternalMetaDataCommon
    ): Promise<void> {
        try {
            const designAsBase64Endoded = this.browserService.toBase64(projectDesign);

            await this.putSmallDesignChange(data.designId, data.projectId, data.designName, designAsBase64Endoded, this.getSessionKeyForDesign(data.designId), metaData, data.unlock, data.exclusiveLock, data.documentAccessMode);
            if (metaData != null) {
                // update meta-data in proxy store
                internalDesign.metaData = metaData;
            }

            internalDesign.designName = data.designName;

            const now: Date = new Date();
            const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
            internalDesign.changeDate = nowUtc;

            this.updateProject(data.designId, data.projectId);
        }
        finally {
            this.defferedRequests[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedRequests[data.designId].deffered != null) {
                const runningDeffered = this.defferedRequests[data.designId].deffered;
                this.defferedRequests[data.designId].running = runningDeffered.promise;

                this.putSmallDesignChangePromiseCommon(this.defferedRequests[data.designId].defferedData as IDefferedData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedRequests[data.designId].deffered = null;
                this.defferedRequests[data.designId].defferedData = null;
            }
        }
    }

    public async smallDesignChange(
        internalDesign: IDesignListItem,
        projectDesign: ProjectDesignBaseEntity | ProjectDesignEntityC2C,
        data: IDefferedData | IDefferedDataC2C,
        metaData: DesignExternalMetaDataCommon
    ): Promise<void> {
        try {
            const designAsBase64Endoded = this.browserService.toBase64(
                "ProjectDesignType" in projectDesign && projectDesign.ProjectDesignType === DesignType.DiaphragmDesign ? projectDesign.DesignName : projectDesign
            );

            await this.putSmallDesignChange(data.designId, data.projectId, data.designName, designAsBase64Endoded, this.getSessionKeyForDesign(data.designId), metaData, data.unlock, data.exclusiveLock, data.documentAccessMode);
            if (metaData != null) {
                // update meta-data in proxy store
                internalDesign.metaData.productName = metaData.productName;
                internalDesign.metaData.designType = metaData.designType;
                internalDesign.metaData.name = metaData.name;
                internalDesign.metaData.region = metaData.region;
                internalDesign.metaData.standard = metaData.standard;
                internalDesign.metaData.designMethod = metaData.designMethod;
                internalDesign.metaData.approvalNumber = metaData.approvalNumber;

                const md = metaData as DesignExternalMetaData;
                internalDesign.metaData.calculationType = md.calculationType;
                internalDesign.metaData.handrailConnectionType = md.handrailConnectionType;
            }

            internalDesign.designName = data.designName;

            const now: Date = new Date();
            const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
            internalDesign.changeDate = nowUtc;

            this.updateProject(data.designId, data.projectId);
        }
        finally {
            this.defferedRequests[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedRequests[data.designId].deffered != null) {
                const runningDeffered = this.defferedRequests[data.designId].deffered;
                this.defferedRequests[data.designId].running = runningDeffered.promise;

                this.putSmallDesignChangePromise(this.defferedRequests[data.designId].defferedData as IDefferedData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedRequests[data.designId].deffered = null;
                this.defferedRequests[data.designId].defferedData = null;
            }
        }
    }


    /* Protected Methods */
    /**
     * Creates promise to update design with new data.
     * @param data - The request data.
     */
    protected async putSmallDesignChangePromiseCommon<TProjectDesign, TDetailedDisplayDesign>(data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        const metaData = cloneDeep(data.metadataOverride);

        await this.smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(internalDesign, projectDesign, data, metaData);
    }

    protected async putSmallDesignChangePromise(data: IDefferedData): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        if (projectDesign.ProjectDesignType !== DesignType.DiaphragmDesign) {
            delete projectDesign.DesignName;
            delete projectDesign.ProjectName;
        }
        const metaData = cloneDeep(data.metadataOverride);

        if (data.displayDesign != null && projectDesign.ProjectDesignType !== DesignType.DiaphragmDesign) {
            detailedDisplayDesignToProjectDesign(projectDesign, data.displayDesign);

            metaData.designMethod = data.displayDesign.designMethodGroup.id;
        }

        await this.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }

    protected async putSmallDesignChangePromiseC2C(data: IDefferedDataC2C): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        delete projectDesign.designName;
        delete projectDesign.projectName;
        const metaData = cloneDeep(data.metadataOverride);

        if (data.displayDesign != null) {
            detailedDisplayDesignToProjectDesignC2C(projectDesign, data.displayDesign);
        }

        await this.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }


    protected async getContent<T>(designId: string): Promise<T> {
        const url = `${environment.documentWebServiceUrl}documentContent/${designId}`;

        this.logServiceRequest('getContent', url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('GET', url), this.getApiOptions());

            this.logServiceResponse('getContent', response);

            const projectDesignAsJsonString = this.browserService.fromBase64(response.body.filecontent);

            try {
                return JSON.parse(projectDesignAsJsonString) as T;
            }
            catch (error) {
                throw new Error('Invalid design on document service.');
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showDesignDoesNotExistModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }



    /**
     * Parse raw data from document service to IDesignListItem.
     * @param data- document data
     * @param now- current date
     */
    protected toIDesignListItem(data: IGetDocumentResponse, useUtc: boolean): IDesignListItem {
        let createDate: Date | null = null;
        let changeDate: Date | null = null;

        if (useUtc) {
            const utc = this.localizationService.moment().utc();
            const nowUtc = new Date(utc.year(), utc.month(), utc.date(), utc.hour(), utc.minute(), utc.second(), utc.millisecond());

            changeDate = data.lastchange != null ? nowUtc : null;
            createDate = data.created != null ? nowUtc : null;
        }
        else {
            changeDate = data.lastchange != null ? new Date(data.lastchange * 1000) : null;
            createDate = data.created != null ? new Date(data.created * 1000) : null;
        }

        const ret: IDesignListItem = {
            id: data.documentid,
            projectId: data.project.projectid,
            projectName: data.project.name,
            changeDate: changeDate,
            createDate: createDate,
            designName: data.name,
            locked: data.locked,
            lockedUser: data.lockedby,
            metaData: this.fromServiceMetaData(data.metadata),
            integrationDocument: null,
            owner: data.owner
        };
        return ret;
    }

    protected async getDesignContentExclusive<TProjectDesign>(
        designId: string,
        sessionId: string,
        projectName?: string,
        adjustContent?: ((conten: TProjectDesign, designName: string, projectName: string) => TProjectDesign) | undefined
    ): Promise<TProjectDesign> {
        const url = `${environment.documentWebServiceUrl}documentContent/GetExclusive`;
        const rData = { documentid: designId, islock: true, key: sessionId };

        try {
            const response = await this.getDesignContentExclusiveInternal(url, rData);
            return this.getContentFromResponse<TProjectDesign>(response, projectName, adjustContent);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content exclusive');

            if (response instanceof HttpErrorResponse) {
                // handle document locked error
                if (response.status == 423) {
                    // leave the upper level to deal with it
                    throw response;
                }

                if (response.status == 404) {
                    this.errorHandlerService.showDesignDoesNotExistModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    protected mapArchiveProjectResponseEntityToIProjectArchive(row: ArchiveProjectResponseModel): IProjectArchive {
        return {
            archived: row.archived,
            created: row.created,
            id: row.projectid,
            designs: row.designs,
            members: row.members,
            name: row.name,
            parentId: row.parentprojectid
        };
    }

    protected getArchiveInternal(dataArchive: ArchiveProjectResponseModel[]): void {
        this._projectsArchive = [];

        for (const item in dataArchive) {
            this._projectsArchive.push(this.mapArchiveProjectResponseEntityToIProjectArchive(dataArchive[item]));
        }
    }

    protected getProjectType(projectName: string): ProjectType {
        if (projectName?.startsWith('#$')) {
            const projectType = projectName.substring(2);

            switch (projectType) {
                case 'draft':
                    return ProjectType.draft;
                case 'template':
                    return ProjectType.template;
            }
        }

        return ProjectType.common;
    }

    protected getProjectName(projectName: string): string {
        if (projectName?.startsWith('#$')) {
            const projectType = projectName.substring(2);
            return this.localizationService.getString(`Agito.Hilti.Profis3.Documents.SpecialProject.${projectType}`);
        }

        return projectName;
    }

    protected getDesignExternalMetadata(metadata: Record<string, string>): DesignExternalMetaData {
        const designExternalMetadata = new DesignExternalMetaData();

        for (const key in metadata) {
            const value = metadata[key];

            switch (key) {
                case 'anchorName':
                case 'productName':
                    designExternalMetadata.productName = value;

                    break;
                case 'region':
                    if (value != null && value != '') {
                        designExternalMetadata.region = parseInt(value, 10);
                    }

                    break;
                case 'standard':
                    if (value != null && value != '') {
                        designExternalMetadata.standard = parseInt(value, 10);
                    }

                    break;
                case 'designMethod':
                    if (value != null && value != '') {
                        designExternalMetadata.designMethod = parseInt(value, 10);
                    }

                    break;
                case 'designType':
                    if (value != null && value != '') {
                        designExternalMetadata.designType = parseInt(value, 10);
                    }

                    break;
                case 'approvalNumber':
                    designExternalMetadata.approvalNumber = value;

                    break;
                case 'calculationType':
                    if (value != null && value != '') {
                        designExternalMetadata.calculationType = parseInt(value, 10);
                    }

                    break;
                case 'handrailConnectionType':
                    if (value != null && value != '') {
                        designExternalMetadata.handrailConnectionType = parseInt(value, 10);
                    }

                    break;

            }
        }

        return designExternalMetadata;
    }

    protected getProjectDesignContent(design: Design): string {
        if (design.isC2C) {
            const data = {...design.designData.projectDesignC2C};
            delete data.designName;
            delete data.projectName;
            return this.browserService.toBase64(data);
        }
        else {
            const data = {...design.designData.projectDesign};
            delete data.DesignName;
            delete data.ProjectName;
            return this.browserService.toBase64(data);
        }
    }

    /**
     * Creates promise to update thumbnail image.
     * @param data Request data
     */
    protected async updateDesignThumbnailImagePromise(data: IDefferedImageData): Promise<void> {
        try {
            await this.uploadDesignImageInternal(data.designId, null, data.thumbnailContent, data.respond);
        }
        finally {
            this.defferedImageUpload[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedImageUpload[data.designId].deffered != null) {
                const runningDeffered = this.defferedImageUpload[data.designId].deffered;
                this.defferedImageUpload[data.designId].running = runningDeffered.promise;

                this.updateDesignThumbnailImagePromise(this.defferedImageUpload[data.designId].defferedData as IDefferedImageData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedImageUpload[data.designId].deffered = null;
                this.defferedImageUpload[data.designId].defferedData = null;
            }
        }
    }

    // make any special stuff on special folders - for now only draft folder
    protected setSpecialProjectProperties(project: Project): void {
        if (project.name.startsWith('#$')) {
            const projectType = project.name.substring(2);
            switch (projectType) {
                case 'draft':
                    project.projectType = ProjectType.draft;
                    break;
                case 'template':
                    project.projectType = ProjectType.template;
                    break;
            }
            project.name = this.localizationService.getString(`Agito.Hilti.Profis3.Documents.SpecialProject.${projectType}`);

        }
    }

    /**
     * Parses some of the results of the read meta-data methods to the specified entity.
     * @param serviceMetaData - Service meta data
     * @param designId - Design id
     * @param designName - Design name
     */
    protected fromServiceMetaData(serviceMetaData: { key: string, value: string }[]): DesignExternalMetaData {
        const ret: DesignExternalMetaData = new DesignExternalMetaData();

        for (const rec of serviceMetaData) {
            switch (rec.key) {
                case 'anchorName':
                case 'productName':
                    ret.productName = rec.value;
                    break;
                case 'region':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.region = parseInt(rec.value);
                    }
                    break;
                case 'standard':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.standard = parseInt(rec.value);
                    }
                    break;
                case 'designMethod':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.designMethod = parseInt(rec.value);
                    }
                    break;
                case 'designType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.designType = parseInt(rec.value);
                    }
                    break;
                case 'approvalNumber':
                    ret.approvalNumber = rec.value;
                    break;
                case 'calculationType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.calculationType = parseInt(rec.value);
                    }
                    break;
                case 'handrailConnectionType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.handrailConnectionType = parseInt(rec.value);
                    }
                    break;
            }
        }

        return ret;
    }

    /**
     * converts the metadata entity to a form appropriate for sending to the document service.
     * @param metaData - Design meta data
     */
    protected toServiceMetaData(metaData: DesignExternalMetaDataCommon): any[] {
        if (metaData == null) {
            return null;
        }

        const ret: any[] = [];

        if (metaData != undefined && metaData != null) {
            (Object.keys(metaData) as (keyof typeof metaData)[]).forEach((key) => {
                const value = metaData[key]?.toString();

                if (value != null && value != '')
                    ret.push({ key: key, value: value });
            });
        }

        return ret;
    }

    /**
     * Returns users on the project
     * @param projectId The project id
     */
    protected async getUsersOnProjectByIdInternal(projectId: string): Promise<ProjectUser[]> {
        const url = `${environment.documentWebServiceUrl}User?projectid=${projectId}`;

        try {
            return (await this.apiService.request<ProjectUser[]>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get users on project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async addNewProject(project: Project): Promise<Project> {
        const url = `${environment.documentWebServiceUrl}project`;

        const rData = {
            name: project.name,
            parentprojectid: project.parentId ?? null,
            isCompanyProject: project.isCompanyProject,
            expanded: project.expanded
        };
        this.logServiceRequest('addNewProject', rData, url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, rData), this.getApiOptions());

            this.logServiceResponse('addNewProject', response);
            project.id = response.body.projectid as string;
            this.setSpecialProjectProperties(project);

            return project;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'post project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) {
                    this.errorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    protected async updateExistingProject(project: Project): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/`;

        const rData = {
            projectid: project.id,
            name: project.name,
            parentprojectid: project.parentId
        };
        this.logServiceRequest('updateExistingProject', rData, url);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, rData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) { // conflict; duplicate name
                    this.errorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    protected async archiveExistingProject(projectId: string): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}project/${projectId}`;

        this.logServiceRequest('archiveExistingProject', url);

        try {
            return (await this.apiService.request<unknown>(new HttpRequest('DELETE', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantArchiveProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async restoreExistingProject(projectId: string): Promise<DocumentGetResponse> {
        const url = `${environment.documentWebServiceUrl}project/dearchive/${projectId}`;

        this.logServiceRequest('restoreExistingProject', url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, {}), this.getApiOptions());
            this.logServiceResponse('restoreExistingProject', response);

            const data = response.body;
            const result: DocumentGetResponse = {
                projects: Object.fromEntries(Object.entries<any>(data.projects).map(([key, it]): [string, DocumentGetProject] => [key, {
                    dateChanged: it.dateChanged,
                    dateCreated: it.dateCreated,
                    id: it.id,
                    name: this.projectNameExists(it.name, it.parentId, it.id) ? this.createUniqueName(it.name, Object.values(this._projectsFlat).map(project => project.name)) : it.name,
                    parentId: it.parentId,
                    owner: it.owner,
                    isCompanyProject: it.isCompanyProject,
                    expanded: it.expanded,
                    readOnly: it.readOnly,
                    expandedState: it.expandedState
                }])),
                documents: Object.fromEntries(Object.entries<any>(data.documents).map(([key, it]): [string, DocumentGetDocument] => [key, {
                    dateChanged: it.dateChanged,
                    dateCreated: it.dateCreated,
                    id: it.id,
                    locked: it.locked,
                    metadata: it.metadata,
                    projectId: it.projectId,
                    lockedUserName: it.lockedUserName,
                    name: it.name,
                    type: it.type,
                    owner: it.owner
                }]))
            };

            return result;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'restore project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantRestoreProjectsBecauseDocumentInUse();
                }

                if (response.status == 409) {
                    this.errorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async removeExistingArchivedProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/permanentArchived/${projectId}`;

        this.logServiceRequest('removeExistingArchivedProject', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async convertExistingProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/convertProject/${projectId}`;

        this.logServiceRequest('convertExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('GET', url), this.getApiOptions());
        }
        catch (response) {
            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async getDocumentsByProjectId(): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}document/get`;
        this.logServiceRequest('getDocumentsByProjectId', url);

        try {
            return (await this.apiService.request<unknown>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get project documents');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async findDesignByNameInternal(designName: string, projectId: string, forceFromServer: boolean): Promise<IDesignListItemCommon> {
        let project = this.findProjectById(projectId);

        if (project != null) {

            if (forceFromServer === true) {
                const data: any = await this.getDocumentsByProjectId();
                const result: DocumentGetResponse = {
                    projects: Object.fromEntries(Object.entries<any>(data.projects).map(([key, it]): [string, DocumentGetProject] => [key, {
                        dateChanged: it.dateChanged,
                        dateCreated: it.dateCreated,
                        id: it.id,
                        name: it.name,
                        parentId: it.parentId,
                        owner: it.owner,
                        isCompanyProject: it.isCompanyProject,
                        expanded: it.expanded,
                        readOnly: it.readOnly,
                        expandedState: it.expandedState
                    }])),
                    documents: Object.fromEntries(Object.entries<any>(data.documents).map(([key, it]): [string, DocumentGetDocument] => [key, {
                        dateChanged: it.dateChanged,
                        dateCreated: it.dateCreated,
                        id: it.id,
                        locked: it.locked,
                        metadata: it.metadata,
                        projectId: it.projectId,
                        lockedUserName: it.lockedUserName,
                        name: it.name,
                        type: it.type,
                        owner: it.owner
                    }]))
                };

                this.getInternal(result);
                project = this.findProjectById(projectId);

                const foundDesign = Object.values(project.designs).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                if (foundDesign != null) {
                    return foundDesign;
                }

                return null;
            }
            else {
                const foundDesign = Object.values(project.designs).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                if (foundDesign != null) {
                    return foundDesign;
                }

                return null;
            }

        }

        return null;
    }

    /**
     * Reads all the locked designs of a project.
     * @param projectId - Project id
     */
    protected async readLockedDesignsOfProject(projectId: string): Promise<CantPerformActionReasonProblematicDocumentInformation[]> {
        interface IGetProjectResponse {
            documents: IGetProjectResponseDocument[];
        }

        interface IGetProjectResponseDocument {
            locked: boolean;
            documentid: string;
            name: string;
            lockedby: string;
        }

        const url: string = `${environment.documentWebServiceUrl}project/` + projectId;

        this.logServiceRequest('readDesignsOfProject', url);

        try {
            const response = await this.apiService.request<IGetProjectResponse>(new HttpRequest('GET', url), this.getApiOptions());

            this.logServiceResponse('readDesignsOfProject', response);
            const designs: CantPerformActionReasonProblematicDocumentInformation[] = [];
            if (response.body.documents instanceof Array) {
                for (const pRaw of response.body.documents) {
                    if (pRaw.locked) {
                        const p: CantPerformActionReasonProblematicDocumentInformation = new CantPerformActionReasonProblematicDocumentInformation();
                        p.designId = pRaw.documentid;
                        p.document = pRaw.name;
                        p.username = pRaw.lockedby;
                        designs.push(p);
                    }
                }
            }

            return designs;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'documents');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Unlock the document on the document service.
     * @param id - design document id.
     */
    protected async postUnlockDocument(id: string): Promise<void> {
        const sessionKey = this.getSessionKeyForDesign(id);
        const url = `${environment.documentWebServiceUrl}documentcontent/${id}/false/${sessionKey}`;
        this.logServiceRequest('postUnlockDocument', url);

        const request = new HttpRequest('POST', url, null, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'design publish failed');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async userHasAccessToProjectInternal(projectId: string): Promise<boolean> {
        const url = `${environment.documentWebServiceUrl}User/isuseronproject/${projectId}`;

        try {
            return (await this.apiService.request<boolean>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            console.error(response);

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    protected async getDesignBasicDocumentInformation(id: string, useUtc: boolean): Promise<IDesignListItem> {
        const url = `${environment.documentWebServiceUrl}document/${id}`;

        this.logServiceRequest('getDesignBasicDocumentInformation', url);

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('GET', url), this.getApiOptions());
            this.logServiceResponse('getDesignBasicDocumentInformation', response);

            return this.toIDesignListItem(response.body, useUtc);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'read document');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Add new empty file and return document id when done.
     * @param projectId - Project id
     * @param designName - Design name
     * @param fileContent - File conctent
     * @param metaData - Meta data
     */
    protected async addNewFile(
        projectId: string,
        designName: string,
        fileContent: string,
        metaData: DesignExternalMetaDataCommon,
        canGenerateUniqueName: boolean,
        ignoreConflict: boolean
    ): Promise<IDocumentServiceDesign> {
        const url = `${environment.documentWebServiceUrl}document`;

        const rData = { projectid: projectId, name: designName, filecontent: fileContent, type: 1, metadata: this.toServiceMetaData(metaData), adjustdocumentname: canGenerateUniqueName };
        this.logServiceRequest('addNewFile', rData, url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, rData), this.getApiOptions());
            this.logServiceResponse('addNewFile', response);
            this.setNewSessionForNewlyCreatedDesign(response.body.documentid, response.body.sessionid);
            return response.body as IDocumentServiceDesign;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add new document');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    if (ignoreConflict !== true) {
                        this.errorHandlerService.showExistingDesignNameModal(response, url, rData);
                    }
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    protected async getDesignContentExclusiveInternal(url: string, rData: any): Promise<HttpResponse<unknown>> {
        this.logServiceRequest('getDesignContentExclusiveInternal', rData, url);

        return await this.apiService.request<unknown>(new HttpRequest('POST', url, rData), this.getApiOptions());
    }

    /**
     * Update design printscreen image when a design report is ready for export
     * @param id - design id
     * @param base64XmlContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    protected async uploadDesignImageInternal(
        designId: string,
        base64XmlContent: string,
        base64ThumbnailXmlContent: string,
        respond = true
    ): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent/UploadDocumentImage`;

        const sessionId = this.getSessionKeyForDesign(designId);

        const rData = {
            key: sessionId,
            documentid: designId,
            modelimage: base64XmlContent,
            thumbnail: base64ThumbnailXmlContent,
            respond
        } as any;

        this.logServiceRequest('uploadDocumentImage', rData, url);

        const request = new HttpRequest('POST', url, rData, {
            responseType: 'json'
        });

        try {
            const response = await this.apiService.request<any>(request, this.getApiOptions());

            if (respond) {
                this.logServiceResponse('uploadDocumentImage', response);
                const data = response.body;

                this.documentThumbnails[data.documentid] = data.thumbnailImageContent;
            }
            else {
                this.documentThumbnails[designId] = base64ThumbnailXmlContent;
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'upload design image failed');

            if (!this.handleSessionExpiredError(response, { designId }, [428])) {
                if (typeof response != 'object' || !('status' in response) || response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }
        }
    }

    protected async removeExistingDesigns(designIds: string[]): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/permanentdelete`;

        this.logServiceRequest('removeExistingDesigns', url);

        const request = new HttpRequest('DELETE', url, designIds, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'remove designs');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, designIds);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: designIds
                    });
                }
            }

            throw response;
        }
    }

    protected setNewSessionForNewlyCreatedDesign(design: string, session: string): void {
        this.designSessionMapping.push({ designId: design, sessionId: session, isCancelled: false });
    }

    protected isCanceledSessionForDesign(designId: string): boolean {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            return true;
        }

        return mapping.isCancelled;
    }

    protected closeSessionForDesign(designId: string): void {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            throw new Error('Session is not opened for the specified design.');
        }

        this.designSessionMapping = this.designSessionMapping.filter(x => x.designId != designId);
    }

    /**
     * the session in which the document was being edited and changed was overtaken.
     * @param response - the entire server error response.
     * @param additionalData - any additional data
     * @param errorsToHandle - what error code should this call handle.
     */
    protected handleSessionExpiredError(response: HttpErrorResponse | unknown, additionalData: { designId: string } | unknown, errorsToHandle: number[]): boolean {
        let errorHandeled = false;

        if (errorsToHandle != null) {
            if (typeof response == 'object' && 'status' in response && response.status == 428 && errorsToHandle.includes(428)) {
                errorHandeled = true;

                this.modalService.openConfirmChange({
                    id: 'traSaveLock',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.Title'),
                    message: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.Description'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.ConfirmButton'),
                    cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.CancelButton'),
                    onConfirm: (modal) => {
                        if (typeof additionalData == 'object' && 'designId' in additionalData && typeof additionalData.designId == 'string') {
                            this.cancelSessionForDesign(additionalData.designId);
                        }

                        this.routingService.navigateToUrl(urlPath.projectAndDesign);
                        modal.close();
                    },
                    onCancel: (modal) => {
                        modal.close();
                    }
                });
            }
        }

        return errorHandeled;
    }

    protected cancelSessionForDesign(designId: string): void {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            return;
        }

        mapping.isCancelled = true;
    }

    protected handleDesignFileLocked(response: HttpErrorResponse | unknown, additionalData: { designId: string } | unknown, errorsToHandle: number[], useUtc: boolean, documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open): boolean {
        let errorHandled = false;

        if (errorsToHandle != null &&
            typeof additionalData == 'object' && 'designId' in additionalData && typeof additionalData.designId == 'string') {

            if (typeof response == 'object' && 'status' in response && response.status == 423 && errorsToHandle.includes(423)) {
                errorHandled = true;

                this.getDesignBasicDocumentInformation(additionalData.designId, useUtc)
                    .then(d => {
                        const ex: CantOpenDesignBecauseLockedByOtherUser = new CantOpenDesignBecauseLockedByOtherUser();
                        ex.username = d.lockedUser;
                        let alertTitle = '';
                        let alertMessage = '';

                        switch (documentAccessMode) {
                            case DocumentAccessMode.Open:
                                alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title';
                                alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description';
                                break;
                            case DocumentAccessMode.Update:
                                alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotSaveInUseBy.Title';
                                alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotSaveInUseBy.Description';
                                break;
                        }

                        this.modalService.openAlertWarning(
                            this.localizationService.getString(alertTitle),
                            formatKeyValue(this.localizationService.getString(alertMessage), { user: ex.username })
                        );
                    })
                    .catch(err => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        errorHandled = false;
                    });
            }
        }

        return errorHandled;
    }

    protected getApiOptions(): ApiOptions {
        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        return {
            supressErrorMessage: true,
        };
    }


    public abstract catchDesignExclusive(response: unknown, design: IBaseDesign): Promise<never>;
    protected abstract getInternal(data: DocumentGetResponse): void;
    protected abstract updateProject(designId: string, projectId: string): void;
    protected abstract addUsersOnProjectByIdInternal(data: ProjectUser): Promise<void>;
    protected abstract removeUsersOnProjectByIdInternal(data: ProjectUser): Promise<void>;
    protected abstract deleteDesignFunc(designId: string): void;

    /**
     * Make a small change to the already exclusively locked and open design, without closing or releasing the content.
     * @param id - design id
     * @param base64XmlContent - the design content in base64 encoded xml format
     * @param sessionId - id of the design change session
     * @param metaData -
     * @param openDesign - if it's called when you open design or close/save
     */
    protected abstract putSmallDesignChange(
        designId: string,
        projectId: string,
        designName: string,
        base64XmlContent: string,
        sessionId: string,
        metaData: DesignExternalMetaDataCommon,
        unlock: boolean,
        exclusiveLock: boolean,
        documentAccessMode: DocumentAccessMode
    ): Promise<void>;


    private getContentFromResponse<TProjectDesign>(response: HttpResponse<unknown>, projectName?: string, adjustContent?: ((conten: TProjectDesign, designName: string, projectName: string) => TProjectDesign) | undefined): TProjectDesign {
        this.logServiceResponse('getContentFromResponse', response);

        const body = (response.body as any);
        const projectDesignAsJsonString = this.browserService.fromBase64(body.filecontent);

        const project = this.findProjectById(body.projectid);

        let data: TProjectDesign;
        try {
            data = JSON.parse(projectDesignAsJsonString);
        }
        catch (error) {
            throw new Error('Invalid design on document service.');
        }
        return adjustContent?.(data, body.documentname, (project?.name ?? projectName)) ?? data;
    }

    private async updateDesignWithNewContentInternal<TProjectDesign, TDetailedDisplayDesign>(
        designId: string,
        defferedData: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>,
        putSmallDesignChangePromiseFn: (data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>) => Promise<void>
    ): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedRequests[designId] == null || (this.defferedRequests[designId] != null && this.defferedRequests[designId].running == null)) {
            this.defferedRequests[designId] = {
                running: putSmallDesignChangePromiseFn(defferedData),
                defferedData: null,
                deffered: null
            };

            await this.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedRequests[designId].deffered == null) {
                this.defferedRequests[designId].deffered = new Deferred();
            }

            this.defferedRequests[designId].defferedData = defferedData;
            await this.defferedRequests[designId].deffered.promise;
        }
    }
}
