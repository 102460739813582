import cloneDeep from 'lodash-es/cloneDeep';
import { Subject } from 'rxjs';

import {
    DesignMethodGroup as DesignMethodGroupC2C
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-method-group';
import {
    DesignStandard as DesignStandardC2C
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-standard';
import { FastenerFamily } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fastener-family';
import { FastenerSize } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fastener-size';
import { FastenerType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fastener-type';
import {
    DesignC2C, ICodeListsC2C, ReportDataC2C
} from '@profis-engineering/pe-ui-c2c/entities/design-c2c';
import {
    DesignCodeList as DesignCodeListC2C
} from '@profis-engineering/pe-ui-c2c/entities/design-code-list';
import {
    ProjectOpenType as ProjectOpenTypeC2C
} from '@profis-engineering/pe-ui-c2c/entities/tracking-data';
import {
    TrackingUsageCounterC2C
} from '@profis-engineering/pe-ui-c2c/entities/tracking-usage-counter';
import {
    ProjectCodeList as ProjectCodeListC2C
} from '@profis-engineering/pe-ui-c2c/enums/project-code-list';
import {
    DesignReportDataClientEntityC2C as IDesignReportDataClientEntityC2C, DialogsEntityC2C,
    LoadCombinationC2C, ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, ConnectionType, DesignMethod, DesignMethodGroup as DesignMethodGroupEnumC2C,
    DesignStandard as DesignStandardEnumC2C,
    LoadCombinationsCalculationType as LoadCombinationsCalculationTypeC2C, LoadType as LoadTypeC2C,
    MaximumAreaReinforcement, MinimumAreaReinforcement, OptimizationStrategy,
    ReportType as ReportTypeC2C, SpacingMaximum, StrutTies
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    ReportOptionEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.ReportService.Shared.Entities';
import { PropertyMetaDataC2C } from '@profis-engineering/pe-ui-c2c/properties/properties';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    Design as DesignCommon, DesignEvent, IBaseDesign as IBaseDesignBase, ICommonUIProperties,
    IDesignDeps, IGlobalMetaPropertiesBase, ISnapshotBase, IUnitProviderObject, Properties
} from '@profis-engineering/pe-ui-common/entities/design';
import {
    PaperSize, ReportType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { IDisposable } from '@profis-engineering/pe-ui-common/helpers/disposable';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    ICalculationResultBase
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import {
    CommonCodeList, CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { AnchorFamily } from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-family';
import {
    AnchorLayoutSymmetry
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-layout-symmetry';
import {
    AnchorSize as AnchorSizeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-size';
import {
    AnchorType as AnchorTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/anchor-type';
import { BrickGroup } from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-group';
import { BrickLayout } from '@profis-engineering/pe-ui-shared/entities/code-lists/brick-layout';
import { BuildingArea } from '@profis-engineering/pe-ui-shared/entities/code-lists/building-area';
import {
    BuildingCategory
} from '@profis-engineering/pe-ui-shared/entities/code-lists/building-category';
import {
    ConcreteCharacteristic as ConcreteCharacteristicEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/concrete-characteristic';
import { ConcreteGrade } from '@profis-engineering/pe-ui-shared/entities/code-lists/concrete-grade';
import {
    DesignMethodGroup
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-method-group';
import {
    DesignStandard
} from '@profis-engineering/pe-ui-shared/entities/code-lists/design-standard';
import { DesignType } from '@profis-engineering/pe-ui-shared/entities/code-lists/design-type';
import {
    HandrailApplicationType as HandrailApplicationTypeEntity
} from '@profis-engineering/pe-ui-shared/entities/code-lists/handrail-application-type';
import { ProfileFamily } from '@profis-engineering/pe-ui-shared/entities/code-lists/profile-family';
import { ProfileSize } from '@profis-engineering/pe-ui-shared/entities/code-lists/profile-size';
import { StandOff } from '@profis-engineering/pe-ui-shared/entities/code-lists/stand-off';
import {
    StiffenerLayout
} from '@profis-engineering/pe-ui-shared/entities/code-lists/stiffener-layout';
import { WindLoadCity } from '@profis-engineering/pe-ui-shared/entities/code-lists/wind-load-city';
import { WindZone } from '@profis-engineering/pe-ui-shared/entities/code-lists/wind-zone';
import { DesignCodeList } from '@profis-engineering/pe-ui-shared/entities/design-code-list';
import {
    DesignDataPe, DesignPe, ICalculateAllAnchor, IDesignState as IDesignStateBase, StandOffType
} from '@profis-engineering/pe-ui-shared/entities/design-pe';
import {
    TrackingUsageCounterPe
} from '@profis-engineering/pe-ui-shared/entities/tracking-usage-counter';
import { ProjectCodeList } from '@profis-engineering/pe-ui-shared/enums/project-code-list';
import {
    ProgressStatus
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Common';
import {
    ProfileFormingNorme
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import {
    BaseplateSize, BrickSize, LoadCombination, LoadCombinationHNAWizard as LoadCombinationHNAWIzard
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignLocationType, ProjectOpenType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import {
    CustomStiffenerEntity, ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    AdvancedCalculationType, ConcreteCharacteristic, ConcreteInCompressionMethod,
    ConcreteSafetyFactorGammaC, ConcreteType, DesignMethodGroup as DesignMethodGroupEnum,
    DesignMethodHNA, DesignStandard as DesignStandardEnum, DesignType as DesignTypeId,
    FatigueLoadType, HandrailApplicationType, HandrailConnectionType,
    LoadCombinationsCalculationType, LoadType, PlateShape, SeismicShearType, SeismicTensionType,
    StaticLoadType, SteelGuideline, StiffenerLocation, StiffenerShape as StiffenerShapeId,
    SupplementaryReinforcementCategory as SupplementaryReinforcementCategoryEnum, WeldLocation,
    WHGType as WHGTypeEnum
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    GenerateReportResponse
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import {
    DesignMethodGroupHelper
} from '@profis-engineering/pe-ui-shared/helpers/design-method-group-helper';
import {
    getAllowedSteelGuidelines
} from '@profis-engineering/pe-ui-shared/helpers/steel-guideline-helper';
import { PropertyMetaData } from '@profis-engineering/pe-ui-shared/properties/properties';

import { CalculationServiceC2C } from '../services/calculation/calculation-c2c.service';
import { CalculationServicePE } from '../services/calculation/calculation-pe.service';
import {
    cancelCalculationRequest, isHnaBasedDesignStandard
} from '../services/calculation/calculation-service-base';
import { CodeListServiceC2C } from '../services/code-list-c2c.service';
import { CodeListService } from '../services/code-list.service';
import { ModulesTrackingService } from '../services/modules-tracking.service';
import { ModulesService } from '../services/modules.service';
import { DesignExternalMetaData } from './design-external-meta-data';

export interface IGlobalMetaProperties extends IGlobalMetaPropertiesBase {
    // Common (will not be moved to module)
    productCodeListProperty: DesignCodeListC2C | DesignCodeList;

    // C2C specific
    sourceMetaPropertySize?: number;
    sourceMetaPropertyType?: number;
    sourceMetaPropertySelectedProduct?: number;
    selectProductTitleTranslationKey?: string;
}

export interface IDesignState extends IDesignStateBase {
    projectDesignC2C: ProjectDesignEntityC2C;
    reportDataC2C: IDesignReportDataClientEntityC2C;
    fastenerFamiliesC2C: FastenerFamily[];
}

export interface ISnapshot extends ISnapshotBase {
    states: IDesignState[];
}

export interface IDesignConstructor {
    id?: string;
    projectDesign?: ProjectDesignBaseEntity;
    model?: { [property: number]: object };
    designCreated?: boolean;
    designName?: string;
    projectId?: string;
    projectName?: string;
    createDate?: Date;
    changeDate?: Date;
    locked?: boolean;
    lockedUser?: string;
    owner?: boolean;
}

export interface IBaseDesign extends IBaseDesignBase {
    designName: string;
    projectName: string;
    integrationDocument?: DocumentModel;
}

export interface ICalculationResult extends ICalculationResultBase<Design> {
    reportData: GenerateReportResponse;
    projectDesign?: ProjectDesignBaseEntity;
}


export interface IAllDesignDeps extends IDesignDeps {
    modulesService: ModulesService;
    moduleTrackingService: ModulesTrackingService;

    // PE
    codeList: CodeListService;
    calculation: CalculationServicePE;

    // C2C
    codeListC2C: CodeListServiceC2C;
    calculationC2C: CalculationServiceC2C;
}

export class AllDesignData extends DesignDataPe {
    /* C2C */
    // Input
    public projectDesignC2C?: ProjectDesignEntityC2C;
    public designCodeListsC2C: ICodeListsC2C;

    // Results
    public reportDataC2C?: ReportDataC2C;
    public enabledFeatures?: string[];
    public dialogs?: DialogsEntityC2C;
}

export class Design extends DesignCommon<AllDesignData, IDesignState> implements IDisposable, IBaseDesign, IUnitProviderObject {
    public projectOpenType: ProjectOpenTypeC2C | ProjectOpenType = ProjectOpenType.Blank;
    public designLocation = DesignLocationType.Unknown;

    public usageCounter: TrackingUsageCounterPe;
    public usageCounterC2C: TrackingUsageCounterC2C;

    public savedProfileSizeFilter: ProfileFormingNorme;
    public currentState: IDesignState;

    public calculateDefer: Deferred<ICalculationResult>;
    public snapshotStateId: string;
    public progressAdvancedBaseplateCalculationSubject = new Subject<ProgressStatus>();
    public progressAdvancedBaseplateCalculation = this.progressAdvancedBaseplateCalculationSubject.asObservable();

    public statusText = '';
    public isAnchorAISolutionSelected = false;
    public isAnchorAISolutionSelectedOnce = false;
    private codeList: CodeListService;
    private calculationServicePE: CalculationServicePE;
    private codeListC2C: CodeListServiceC2C;
    private codeListCommon: CommonCodeListServiceBase;
    private modulesService: ModulesService;
    private moduleTrackingService: ModulesTrackingService;
    private calculationServiceC2C: CalculationServiceC2C;

    private pendingCloseDesign: boolean;

    constructor(designDeps: IAllDesignDeps, properties: ICommonUIProperties, design?: IDesignConstructor) {
        super(designDeps, properties);

        this.codeList = designDeps.codeList;
        this.calculationServicePE = designDeps.calculation;
        this.codeListC2C = designDeps.codeListC2C;
        this.calculationServiceC2C = designDeps.calculationC2C;
        this.codeListCommon = designDeps.commonCodeList;

        this.modulesService = designDeps.modulesService;
        this.moduleTrackingService = designDeps.moduleTrackingService;

        this.stateChanged = this.stateChanged.bind(this);

        this.uniqueId = this.guid.new();
        this.scopeDesignId = `design_${this.uniqueId.replace(/-/g, '_')}`;
        this.properties = new Properties();
        this.states = [];
        this.pendingUndoRedoActions = [];
        this.calculateDefer = new Deferred<ICalculationResult>();
        this.createDate = new Date();
        this.usageCounter = new TrackingUsageCounterPe(this.logger);
        this.usageCounterC2C = new TrackingUsageCounterC2C(this.logger);
        this.isTemplate = false;
        this.confirmChangeInProgress = false;
        this.integrationDocument = null;
        this.confirmedProperties = [];

        this.designData = {
            designCodeLists: {},
            designCodeListsC2C: {},
            asadData: { input: {} as any },
            projectDesign: {} as any,
            reportData: {} as any
        };

        this.onStateChanged(this.stateChanged);

        if (design != null) {
            this.model = design.model ?? {};

            this.id = design.id;
            this.designData.projectDesign = design.projectDesign;
            this.designName = design.designName;
            this.projectId = design.projectId;
            this.projectName = design.projectName;
            this.createDate = design.createDate;
            this.changeDate = design.changeDate;
            this.locked = design.locked;
            this.lockedUser = design.lockedUser;
            this.owner = design.owner;
        }

        // default values
        this.model = this.model ?? {};

        // clear changes
        this.modelChanges.clear();
    }

    public get projectDesign() {
        return this.isC2C ? this.designData.projectDesignC2C : this.designData.projectDesign;
    }

    public get metaData() {
        return DesignExternalMetaData.getMetaDataFromDesign(this);
    }

    public get canUndo() {
        return this.states.findIndex((state) => state === this.currentState) > 0;
    }
    public get canRedo() {
        const index = this.states.findIndex((state) => state === this.currentState);

        return index >= 0 && index < this.states.length - 1;
    }

    public get currentStateIdx() {
        return this.states.findIndex((state) => state === this.currentState);
    }

    public get isStateChanged() {
        return this.savedStateIdx != this.currentStateIdx;
    }

    public get isC2C() {
        return this.designType.id == DesignTypeId.Concrete2Concrete;
    }

    public get reportTemplateId() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportTemplateId.id] as number;
        }
        return this.model[PropertyMetaData.Option_ReportTemplateId.id] as number;
    }
    public set reportTemplateId(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportTemplateId.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportTemplateId.id] = value;
    }

    public get reportFateningPoint() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportRebarApplication.id] as string;
        }
        return this.model[PropertyMetaData.Option_ReportFasteningPoint.id] as string;
    }
    public set reportFateningPoint(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportRebarApplication.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportFasteningPoint.id] = value;
    }

    public get reportNotes() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportNotes.id] as string;
        }
        return this.model[PropertyMetaData.Option_ReportNotes.id] as string;
    }
    public set reportNotes(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportNotes.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportNotes.id] = value;
    }

    public get forceZ() {
        return this.model[PropertyMetaData.Loads_ForceZ.id] as number;
    }
    public set forceZ(value) {
        this.model[PropertyMetaData.Loads_ForceZ.id] = value;
    }

    public get forceX() {
        return this.model[PropertyMetaData.Loads_ForceX.id] as number;
    }
    public set forceX(value) {
        this.model[PropertyMetaData.Loads_ForceX.id] = value;
    }

    public get forceY() {
        return this.model[PropertyMetaData.Loads_ForceY.id] as number;
    }
    public set forceY(value) {
        this.model[PropertyMetaData.Loads_ForceY.id] = value;
    }

    public get momentZ() {
        return this.model[PropertyMetaData.Loads_MomentZ.id] as number;
    }
    public set momentZ(value) {
        this.model[PropertyMetaData.Loads_MomentZ.id] = value;
    }

    public get momentX() {
        return this.model[PropertyMetaData.Loads_MomentX.id] as number;
    }
    public set momentX(value) {
        this.model[PropertyMetaData.Loads_MomentX.id] = value;
    }

    public get momentY() {
        return this.model[PropertyMetaData.Loads_MomentY.id] as number;
    }
    public set momentY(value) {
        this.model[PropertyMetaData.Loads_MomentY.id] = value;
    }

    public get designMethodGroup() {
        if (this.isC2C) {
            const codeList = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignMethodGroupsC2C] as DesignMethodGroupC2C[];
            return codeList.find((designMethodGroup) => designMethodGroup.id == this.model[this.designMethodGroupPropertyC2C]);
        }
        else {
            const codeList = this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[];
            return codeList.find((designMethodGroup) => designMethodGroup.id == this.model[PropertyMetaData.Option_DesignMethodGroupId.id]);
        }
    }
    public set designMethodGroup(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_DesignMethodGroup.id] = value != null ? value.id : null;
        }
        else {
            this.model[PropertyMetaData.Option_DesignMethodGroupId.id] = value != null ? value.id : null;
        }
    }

    public get reportLoadCombination() {
        return this.model[PropertyMetaData.Option_ReportLoadCombination.id] as string;
    }
    public set reportLoadCombination(value) {
        this.model[PropertyMetaData.Option_ReportLoadCombination.id] = value;
    }

    public get applicationType() {
        return this.model[PropertyMetaDataC2C.General_C2C_ApplicationType.id] as ApplicationType;
    }
    public set applicationType(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.General_C2C_ApplicationType.id] = value;
        }
    }

    private get designMethodGroupPropertyC2C(): number {
        return PropertyMetaDataC2C.Option_C2C_DesignMethodGroup.id;
    }

    private get calculationService() {
        return this.isC2C ? this.calculationServiceC2C : this.calculationServicePE;
    }

    public onStructuralCalculationSoftwareChanged(fn: (design: Design, state: IDesignState) => void) {
        this.on(DesignEvent.structuralCalculationSoftwareChanged, fn);
    }

    public setSavedStateIdx() {
        this.savedStateIdx = this.currentStateIdx;
    }

    public addModelChange(propertyId: number, noCollapse?: boolean, value?: unknown, oldValue?: unknown) {
        this.addModelChangeInternal(propertyId, noCollapse, value as object, oldValue as object);

        return this.calculationService.calculateAsync(this);
    }

    public dispose() {
        cancelCalculationRequest(this, this.logger);

        this.calculateDefer.promise.catch(() => { /* Nothing to do. */ });
        this.calculateDefer.reject();
        this.calculateDefer = new Deferred<ICalculationResult>();

        this.off(DesignEvent.stateChanged, this.stateChanged);
    }

    public hasETAG() {
        const c = this.designMethodGroupsAvailable.filter((item) => item.id == DesignMethodGroupEnum.ETAG || item.id == DesignMethodGroupEnum.SOFA).length;
        return this.designStandard.id == DesignStandardEnum.ETAG && c >= 2 && this.designType.id == DesignTypeId.Concrete;
    }

    public setAnchorAISolutionSelected(isSelected : boolean){
        this.isAnchorAISolutionSelected = isSelected;
    }

    public setAnchorAISolutionSelectedOnce(isSelected : boolean){
        this.isAnchorAISolutionSelectedOnce = isSelected;
    }

    public async processDesignClose(ignoreErrors: boolean): Promise<void> {
        if (this.pendingCloseDesign) {
            return;
        }

        this.pendingCloseDesign = true;

        // cancel all calculations
        this.resolveCalculation();

        const licensePromise = this.user.releaseAllFloatingLicenses(ignoreErrors);
        const trackingPromise = (this.isTemplate ? this.moduleTrackingService.trackOnTemplateClose(this) : this.moduleTrackingService.trackOnDesignClose(this))
            .then(() => this.usageCounter.resetCounter());

        await Promise.all([trackingPromise, licensePromise]);
    }

    public processDesignBrowserUnload(sessionKey: string) {
        // cancel all calculations
        this.resolveCalculation();

        if (this.isTemplate) {
            return Promise.resolve();
        }

        return this.moduleTrackingService.handleDesignBrowserUnload(this, sessionKey);
    }

    public createSnapshot() {
        return {
            stateId: this.snapshotStateId,
            states: cloneDeep(this.states),
            currentState: this.states.findIndex((state) => state === this.currentState)
        } as ISnapshot;
    }

    public isStateSnapshot(snapshot: ISnapshot) {
        return this.snapshotStateId == snapshot.stateId;
    }

    public reloadState() {
        if (this.isC2C) {
            this.calculationServiceC2C.loadState(this, this.states.findIndex((state) => state === this.currentState));
        }
        else {
            this.calculationServicePE.loadState(this, this.states.findIndex((state) => state === this.currentState));
        }
    }

    public getLanguageById(languageId: number) {
        return (this.codeListCommon.commonCodeLists[CommonCodeList.Language] as Language[]).find(language => language.id == languageId);
    }

    public updateProjectDesignOptionsC2C(options: ReportOptionEntityC2C) {
        this.designData.projectDesignC2C.options.reportNotes = options.notes;
        this.designData.projectDesignC2C.options.reportRebarApplication = options.fasteningPoint;
        this.designData.projectDesignC2C.options.customPictures = options.customPictures;
        this.designData.projectDesignC2C.options.noteShearLoad = options.noteShearLoad;
        this.designData.projectDesignC2C.options.noteInstallationData = options.noteInstallationData;
        this.designData.projectDesignC2C.options.noteTensionLoad = options.noteTensionLoad;
        this.designData.projectDesignC2C.options.noteLoadCaseResultingRebarForces = options.noteLoadResultingAnchorForces;
        this.designData.projectDesignC2C.options.reportLanguageId = options.reportLanguageId;
        this.designData.projectDesignC2C.options.reportFirstPage = options.firstPage;
        this.designData.projectDesignC2C.options.reportTemplateId = options.reportTemplateId;
        this.designData.projectDesignC2C.options.reportType = options.type;
        this.designData.projectDesignC2C.options.reportPaperSize = options.paperSize;
        this.designData.projectDesignC2C.options.reportCompanyName = options.companyName;
        this.designData.projectDesignC2C.options.reportAddress = options.address;
        this.designData.projectDesignC2C.options.reportContactPerson = options.contactPerson;
        this.designData.projectDesignC2C.options.reportPhoneNumber = options.phoneNumber;
        this.designData.projectDesignC2C.options.reportEmail = options.email;
        this.designData.projectDesignC2C.options.trimbleConnectUpload = options.trimbleConnectUpload;
        this.designData.projectDesignC2C.options.trimbleConnectLocation = options.trimbleConnectLocation;
        this.designData.projectDesignC2C.options.trimbleConnectReportName = options.trimbleConnectReportName;
        this.designData.projectDesignC2C.options.trimbleConnectFolderId = options.trimbleConnectFolderId;
    }

    public loadAnchorFamilyDetailed() {
        const anchorFamilies = (this.designData.designCodeLists[this.globalMetaProperties.productCodeListProperty] as AnchorFamily[]);
        for (const anchorFamily of anchorFamilies) {
            const anchorFamilyDetailed = this.designData.reportData.AnchorFamiliesDetailed?.find(detailed => detailed.FastenerFamilyGroupId == anchorFamily.id);

            if (anchorFamilyDetailed != null) {
                anchorFamily.setDetailedFromService(anchorFamilyDetailed);
            }
        }
    }

    public updateModelFromModel(model: { [property: number]: unknown }) {
        this.model = model;

        return this.model;
    }

    public updateProjectDesign(projectDesign: ProjectDesignBaseEntity) {
        this.designData.projectDesign = projectDesign;

        return this.designData.projectDesign;
    }

    public updateProjectDesignC2C(projectDesignC2C: ProjectDesignEntityC2C) {
        this.designData.projectDesign = ({} as ProjectDesignBaseEntity);
        this.designData.projectDesignC2C = projectDesignC2C;

        return this.designData.projectDesignC2C;
    }

    public updateCalculateAllData(calculateAllData: ICalculateAllAnchor[]) {
        this.designData.calculateAllData = calculateAllData;

        return this.designData.calculateAllData;
    }

    public resolveCalculation() {
        this.loading = false;
        this.setPendingCalculation(false);

        this.calculateDefer.resolve({ design: this, calculationCanceled: true, messagesClosed: Promise.resolve(), reportData: null });
        this.calculateDefer = new Deferred<ICalculationResult>();

        cancelCalculationRequest(this, this.logger);
    }

    private getDesignStandardInternal() {
        if (this.isC2C) {
            const codeList = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignStandardsC2C] as DesignStandardC2C[];
            return codeList.find((designStandardC2C) => designStandardC2C.id == this.model[PropertyMetaDataC2C.Option_C2C_DesignStandard.id]);
        }
        else {
            const codeList = this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];
            return codeList.find((designStandard) => designStandard.id == this.model[PropertyMetaData.Option_DesignStandard.id]);
        }
    }


    // Already moved to DesignPe
    public get anchorPlateFactor() {
        return this.model[PropertyMetaData.Option_AnchorPlateFactor.id] as number;
    }
    public set anchorPlateFactor(value) {
        this.model[PropertyMetaData.Option_AnchorPlateFactor.id] = value ?? null;
    }

    public get edgeDistanceDisplayType() {
        return this.model[PropertyMetaData.Option_EdgeDistanceDisplayType.id];
    }
    public set edgeDistanceDisplayType(value) {
        this.model[PropertyMetaData.Option_EdgeDistanceDisplayType.id] = value ?? null;
    }

    public get trimbleConnectUpload() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectUpload.id] as boolean;
        }
        return this.model[PropertyMetaData.Option_TrimbleConnectUpload.id] as boolean;
    }
    public set trimbleConnectUpload(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_TrimbleConnectUpload.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_TrimbleConnectUpload.id] = value;
    }

    public get brickLayout() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BrickLayout] as BrickLayout[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.BaseMaterial_BrickLayout.id]);
    }

    public get anchorFastenerTypeImage() {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.designMethodGroup.id)
            ? this.designData.designCodeLists[DesignCodeList.AnchorTypeAC58] as AnchorTypeEntity[]
            : this.designData.designCodeLists[DesignCodeList.AnchorType] as AnchorTypeEntity[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.AnchorLayout_Type.id]);
    }

    public get anchorFastenerFamilyImage() {
        const codeList = this.designData.designCodeLists[this.globalMetaProperties.productCodeListProperty] as AnchorFamily[];

        return codeList.find((item) => item.id == this.model[this.globalMetaProperties.sourceMetaProperty]);
    }

    public get trimbleConnectLocation() {
        return this.model[PropertyMetaData.Option_TrimbleConnectLocation.id] as string;
    }
    public set trimbleConnectLocation(value) {
        this.model[PropertyMetaData.Option_TrimbleConnectLocation.id] = value;
    }

    public get trimbleConnectReportName() {
        return this.model[PropertyMetaData.Option_TrimbleConnectReportName.id] as string;
    }
    public set trimbleConnectReportName(value) {
        this.model[PropertyMetaData.Option_TrimbleConnectReportName.id] = value;
    }

    public get trimbleConnectFolderId() {
        return this.model[PropertyMetaData.Option_TrimbleConnectFolderId.id] as string;
    }
    public set trimbleConnectFolderId(value) {
        this.model[PropertyMetaData.Option_TrimbleConnectFolderId.id] = value;
    }

    public get reportCompanyName() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportCompanyName.id] as string;
        }
        return this.model[PropertyMetaData.Option_ReportCompanyName.id] as string;
    }
    public set reportCompanyName(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportCompanyName.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportCompanyName.id] = value;
    }

    public get reportAddress() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportAddress.id] as string;
        }
        return this.model[PropertyMetaData.Option_ReportAddress.id] as string;
    }
    public set reportAddress(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportAddress.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportAddress.id] = value;
    }

    public get reportContactPerson() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportContactPerson.id] as string;
        }
        return this.model[PropertyMetaData.Option_ReportContactPerson.id] as string;
    }
    public set reportContactPerson(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportContactPerson.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportContactPerson.id] = value;
    }

    public get reportPhoneNumber() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportPhoneNumber.id] as string;
        }
        return this.model[PropertyMetaData.Option_ReportPhoneNumber.id] as string;
    }
    public set reportPhoneNumber(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportPhoneNumber.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportPhoneNumber.id] = value;
    }

    public get reportEmail() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportEmail.id] as string;
        }
        return this.model[PropertyMetaData.Option_ReportEmail.id] as string;
    }
    public set reportEmail(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportEmail.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportEmail.id] = value;
    }

    public get reportFirstPage() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportFirstPage.id] as number;
        }
        return this.model[PropertyMetaData.Option_ReportFirstPage.id] as number;
    }
    public set reportFirstPage(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportFirstPage.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportFirstPage.id] = value;
    }

    public get decisiveLoadCombination() {
        if (this.designData.reportData.DecisiveLoadCombinationIndex != null && this.loadCombinations != null && this.designData.reportData.DecisiveLoadCombinationIndex < this.loadCombinations.length) {
            return this.loadCombinations[this.designData.reportData.DecisiveLoadCombinationIndex];
        }
        return null;
    }

    public get isAnchorPlateStressNotPossible() {
        return !this.designData.reportData.FeCalculationComplete;
    }

    public get reportPaperSizeId() {
        return this.model[PropertyMetaData.Option_ReportPaperSizeId.id] as PaperSize;
    }
    public set reportPaperSizeId(value) {
        this.model[PropertyMetaData.Option_ReportPaperSizeId.id] = value;
    }

    public get reportTypeId() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportType.id] as ReportTypeC2C;
        }
        return this.model[PropertyMetaData.Option_ReportTypeId.id] as ReportType;
    }
    public set reportTypeId(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportType.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportTypeId.id] = value;
    }

    public get noteDisplacements() {
        return this.model[PropertyMetaData.Option_NoteDisplacements.id] as string;
    }
    public set noteDisplacements(val: string) {
        this.model[PropertyMetaData.Option_NoteDisplacements.id] = val;
    }

    public get noteInstallationData() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_NoteInstallationData.id] as string;
        }
        return this.model[PropertyMetaData.Option_NoteInstallationData.id] as string;
    }
    public set noteInstallationData(val: string) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_NoteInstallationData.id] = val;
            return;
        }
        this.model[PropertyMetaData.Option_NoteInstallationData.id] = val;
    }
    public get noteTensionLoad() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_NoteTensionLoad.id] as string;
        }
        return this.model[PropertyMetaData.Option_NoteTensionLoad.id] as string;
    }
    public set noteTensionLoad(val: string) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_NoteTensionLoad.id] = val;
            return;
        }
        this.model[PropertyMetaData.Option_NoteTensionLoad.id] = val;
    }

    public get noteShearLoad() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_NoteShearLoad.id] as string;
        }
        return this.model[PropertyMetaData.Option_NoteShearLoad.id] as string;
    }
    public set noteShearLoad(val: string) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_NoteShearLoad.id] = val;
            return;
        }
        this.model[PropertyMetaData.Option_NoteShearLoad.id] = val;
    }

    public get noteCombinedTensionAndShearLoad() {
        return this.model[PropertyMetaData.Option_NoteCombinedTensionAndShearLoad.id] as string;
    }
    public set noteCombinedTensionAndShearLoad(val: string) {
        this.model[PropertyMetaData.Option_NoteCombinedTensionAndShearLoad.id] = val;
    }

    public get noteLoadCaseResultingAnchorForces() {
        return this.model[PropertyMetaData.Option_NoteLoadCaseResultingAnchorForces.id] as string;
    }
    public set noteLoadCaseResultingAnchorForces(val: string) {
        this.model[PropertyMetaData.Option_NoteLoadCaseResultingAnchorForces.id] = val;
    }

    public get customPictures() {
        return this.model[PropertyMetaData.Option_CustomPictures.id] as string[];
    }
    public set customPictures(val: string[]) {
        this.model[PropertyMetaData.Option_CustomPictures.id] = val;
    }

    public get designMethodHNA() {
        return this.model[PropertyMetaData.Loads_DesignMethodHNA.id] as DesignMethodHNA;
    }

    public get loadCombinationHNAWizard() {
        return this.model[PropertyMetaData.Loads_LoadCombinationHNAWizard.id] as LoadCombinationHNAWIzard;
    }

    public get verticalPunctualLoad() {
        return this.model[PropertyMetaData.Loads_VerticalPunctualLoad.id] as number;
    }
    public set verticalPunctualLoad(value) {
        this.model[PropertyMetaData.Loads_VerticalPunctualLoad.id] = value;
    }

    public get verticalLoad() {
        return this.model[PropertyMetaData.Loads_VerticalLoad.id] as number;
    }
    public set verticalLoad(value) {
        this.model[PropertyMetaData.Loads_VerticalLoad.id] = value;
    }

    public get infillLoadOutwards() {
        return this.model[PropertyMetaData.Loads_InfillLoadOutwards.id] as number;
    }
    public set infillLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_InfillLoadOutwards.id] = value;
    }

    public get infillLoadInwards() {
        return this.model[PropertyMetaData.Loads_InfillLoadInwards.id] as number;
    }
    public set infillLoadInwards(value) {
        this.model[PropertyMetaData.Loads_InfillLoadInwards.id] = value;
    }

    public get infillPunctualLoadOutwards() {
        return this.model[PropertyMetaData.Loads_InfillPunctualLoadOutwards.id] as number;
    }
    public set infillPunctualLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_InfillPunctualLoadOutwards.id] = value;
    }

    public get infillPunctualLoadInwards() {
        return this.model[PropertyMetaData.Loads_InfillPunctualLoadInwards.id] as number;
    }
    public set infillPunctualLoadInwards(value) {
        this.model[PropertyMetaData.Loads_InfillPunctualLoadInwards.id] = value;
    }

    public get linearLoadOutwards() {
        return this.model[PropertyMetaData.Loads_LinearLoadOutwards.id] as number;
    }
    public set linearLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_LinearLoadOutwards.id] = value;
    }

    public get linearLoadInwards() {
        return this.model[PropertyMetaData.Loads_LinearLoadInwards.id] as number;
    }
    public set linearLoadInwards(value) {
        this.model[PropertyMetaData.Loads_LinearLoadInwards.id] = value;
    }

    public get linearLoadOutwardsHeight() {
        return this.model[PropertyMetaData.Loads_LinearLoadOutwardsHeight.id] as number;
    }
    public set linearLoadOutwardsHeight(value) {
        this.model[PropertyMetaData.Loads_LinearLoadOutwardsHeight.id] = value;
    }

    public get linearLoadInwardsHeight() {
        return this.model[PropertyMetaData.Loads_LinearLoadInwardsHeight.id] as number;
    }
    public set linearLoadInwardsHeight(value) {
        this.model[PropertyMetaData.Loads_LinearLoadInwardsHeight.id] = value;
    }

    public get punctualLoadOutwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadOutwards.id] as number;
    }
    public set punctualLoadOutwards(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadOutwards.id] = value;
    }

    public get punctualLoadInwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadInwards.id] as number;
    }
    public set punctualLoadInwards(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadInwards.id] = value;
    }

    public get punctualLoadInwardsHeight() {
        return this.model[PropertyMetaData.Loads_PunctualLoadInwardsHeight.id] as number;
    }
    public set punctualLoadInwardsHeight(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadInwardsHeight.id] = value;
    }

    public get punctualLoadOutwardsHeight() {
        return this.model[PropertyMetaData.Loads_PunctualLoadOutwardsHeight.id] as number;
    }
    public set punctualLoadOutwardsHeight(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadOutwardsHeight.id] = value;
    }

    public get isStainlessSteelDisabled() {
        const item = this.properties.properties[PropertyMetaData.Option_StainlessSteel.id];
        return item !== undefined ? item.disabled : true;
    }

    public get isStainlessSteelHidden() {
        const item = this.properties.properties[PropertyMetaData.Option_StainlessSteel.id];
        return item !== undefined ? item.hidden : true;
    }

    public get selectedLoadCombinationId() {
        return this.model[PropertyMetaData.Loads_SelectedLoadCombination.id] as string;
    }
    public set selectedLoadCombinationId(value) {
        this.model[PropertyMetaData.Loads_SelectedLoadCombination.id] = value;
    }

    public get profileWidth() {
        return this.model[PropertyMetaData.Profile_CustomWidth.id] as number;
    }

    public get profileWidth2() {
        return this.model[PropertyMetaData.Profile_CustomWidth2.id] as number;
    }

    public get profileHeight() {
        return this.model[PropertyMetaData.Profile_CustomHeight.id] as number;
    }

    public get profileFlangeThickness() {
        return this.model[PropertyMetaData.Profile_CustomFlangeThickness.id] as number;
    }

    public get stiffenerShape() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerShape.id] as StiffenerShapeId;
    }

    public get customStiffeners() {
        return this.model[PropertyMetaData.Stiffener_CustomStiffener.id] as CustomStiffenerEntity[];
    }
    public set customStiffeners(value) {
        this.model[PropertyMetaData.Stiffener_CustomStiffener.id] = value;
    }

    public get profileFamily() {
        const codelist = this.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];

        return codelist.find((item) => item.id == this.model[PropertyMetaData.Profile_ProfileFamilyId.id]);
    }

    public get globalMetaProperties(): IGlobalMetaProperties {
        if (this.isC2C) {
            switch (this.connectionType) {
                case ConnectionType.StructuralJoints:
                case ConnectionType.Splices:
                    return DesignC2C.getSplicesStructuralJointsMetaProperties();
                case ConnectionType.ConcreteOverlay:
                    return DesignC2C.getConcreteOverlayMetaProperties(this.designStandardC2C?.id ?? 0);
                default:
                    return {} as IGlobalMetaProperties;
            }
        }
        else {
            return DesignPe.getAnchorMetaProperties(this.designMethodGroup.id);
        }
    }

    public get baseplateDesignData() {
        return this.designData.reportData.BaseplateDesignData;
    }

    public get useULSStresses() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_UseULSStresses.id] as boolean;
    }
    public set useULSStresses(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_UseULSStresses.id] = value;
    }

    public get smartAnchorCategory() {
        return this.model[PropertyMetaData.SmartAnchor_Category.id] as number;
    }

    public get smartAnchorApplication() {
        return this.model[PropertyMetaData.SmartAnchor_Application.id] as number;
    }

    public get smartAnchorSuggestedAnchorSelected() {
        return this.model[PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id] as number;
    }

    public get smartAnchorUserSelected() {
        return this.model[PropertyMetaData.AnchorLayout_AnchorFamily.id] as number;
    }

    public get smartAnchorUserChosenVisible() {
        return this.properties.get(PropertyMetaData.AnchorUserChosen.id).hidden;
    }

    public get smartAnchorHiltiRecommendedAnchors() {
        return this.model[PropertyMetaData.SmartAnchor_AnchorGroupsForAsad.id] as number[];
    }

    public get isCBFEMCalculation() {
        return this.designData.reportData.IsCBFEMCalculation;
    }

    public get isHandrailCBFEMCalculation() {
        return this.designData.reportData.IsHandrailCBFEMCalculation;
    }

    public get useTallProfile() {
        return this.designData.reportData.UseTallProfile;
    }

    public get loadType() {
        return this.model[PropertyMetaData.Loads_LoadType.id] as LoadType;
    }
    public set loadType(value) {
        this.model[PropertyMetaData.Loads_LoadType.id] = value;
    }

    public get seismicTensionType() {
        return this.model[PropertyMetaData.Loads_SeismicTensionType.id] as SeismicTensionType;
    }
    public set seismicTensionType(value) {
        this.model[PropertyMetaData.Loads_SeismicTensionType.id] = value;
    }

    public get seismicShearType() {
        return this.model[PropertyMetaData.Loads_SeismicShearType.id] as SeismicShearType;
    }
    public set seismicShearType(value) {
        this.model[PropertyMetaData.Loads_SeismicShearType.id] = value;
    }

    public get drillingMethod() {
        return this.model[PropertyMetaData.BaseMaterial_DrillingMethod.id];
    }
    public set drillingMethod(value) {
        this.model[PropertyMetaData.BaseMaterial_DrillingMethod.id] = value;
    }

    public get isVariableLoadsPresent(): boolean {
        return this.loadType == LoadType.Fatigue ||
            this.loadType == LoadType.Static && this.staticLoadTypeId == StaticLoadType.Characteristic;
    }

    public get isLoadCombinationActive() {
        return this.model[PropertyMetaData.Loads_AreLoadCombinationsActive.id] as boolean;
    }
    public set isLoadCombinationActive(value) {
        this.model[PropertyMetaData.Loads_AreLoadCombinationsActive.id] = value;
    }

    public get isFactoredSustainedLoadsActive() {
        return this.model[PropertyMetaData.Loads_AreFactoredSustainedLoadsActive.id] as boolean;
    }

    public get isFatigueDesign() {
        return this.model[PropertyMetaData.Loads_LoadType.id] == LoadType.Fatigue;
    }

    public get isFireDesign() {
        return this.model[PropertyMetaData.Loads_LoadType.id] == LoadType.Fire;
    }

    public get staticLoadTypeId() {
        return this.model[PropertyMetaData.Loads_StaticLoadType.id] as StaticLoadType;
    }

    public get loadCombinations() {
        return this.model[PropertyMetaData.Loads_LoadCombinations.id] as LoadCombination[];
    }
    public set loadCombinations(value) {
        this.model[PropertyMetaData.Loads_LoadCombinations.id] = value;
    }

    public get isSustainedLoadsPresent() {
        const isFactoredSustainedLoadsActivePresent = () => !this.properties.get(PropertyMetaData.Loads_AreFactoredSustainedLoadsActive.id).hidden;

        const isSustainedLoadsValuePresent = () => !this.properties.get(PropertyMetaData.Loads_ForceZSustained.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentXSustained.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentYSustained.id).hidden;

        const isVariableLoadsValuePresent = () => !this.isVariableLoadsPresent &&
            (!this.properties.get(PropertyMetaData.Loads_ForceZVariable.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentXVariable.id).hidden ||
            !this.properties.get(PropertyMetaData.Loads_MomentYVariable.id).hidden);

        const isSustainedLoadCombinationsPresent = () => this.isLoadCombinationActive && this.loadCombinations.some(l => l.HasSustainedLoads);

        return isFactoredSustainedLoadsActivePresent() ||
            isSustainedLoadsValuePresent() ||
            isVariableLoadsValuePresent() ||
            isSustainedLoadCombinationsPresent();
    }

    public get fatigueLoadType() {
        return this.model[PropertyMetaData.Loads_FatigueLoadType.id] as FatigueLoadType;
    }

    public get alternatingLoads(): boolean {
        return this.designType.id == DesignTypeId.Concrete &&
            this.loadType == LoadType.Fatigue &&
            this.fatigueLoadType == FatigueLoadType.Alternating;
    }

    public get designMethodName() {
        if (this.designData.reportData == null) {
            return null;
        }

        return this.designData.reportData.DesignMethodName;
    }

    public get approvalNumber() {
        if (this.designData.reportData == null || this.isC2C) {
            return null;
        }

        return this.designData.reportData.Approval.Number;
    }

    public get ApprovalNumberSTO() {
        if (this.designData.reportData == null) {
            return null;
        }

        return this.designData.reportData.ApprovalSTO.Number;
    }

    public get ApprovalNumberUKTA() {
        if (this.designData == null) {
            return undefined;
        }

        return this.designData.reportData.ApprovalUKTA.Number;
    }

    public get handrailApplication() {
        const codeList = this.designData.designCodeLists[DesignCodeList.HandrailApplication] as HandrailApplicationTypeEntity[];
        if (codeList == null) {
            return null;
        }

        return codeList.find((item) => item.id == this.model[PropertyMetaData.Application_Application.id]);
    }

    public get steelGuideline() {
        return this.model[PropertyMetaData.Option_SteelGuideline.id] as SteelGuideline;
    }

    public get steelMethod() {
        const steelGuideline = getAllowedSteelGuidelines(this.codeList.projectCodeLists, this.designStandard.id, this.region.id)
            .find((steelGuideline) => steelGuideline.id == this.steelGuideline);

        return this.localization.getString(steelGuideline.steelMethodTranslationKey);
    }

    public get designStandard() {
        return this.getDesignStandardInternal();
    }

    public set designStandard(value) {
        this.model[PropertyMetaData.Option_DesignStandard.id] = value != null ? value.id : null;
    }

    public get designType() {
        return this.modulesService.designTypes.find((designType) => designType.id == this.model[PropertyMetaData.ProjectDesignType.id]) as DesignType;
    }

    public get designName() {
        return this.model[PropertyMetaData.Option_DesignName.id] as string;
    }
    public set designName(value) {
        this.model[PropertyMetaData.Option_DesignName.id] = value;
    }

    public get projectName() {
        return this.model[PropertyMetaData.ProjectName.id] as string;
    }
    public set projectName(value) {
        this.model[PropertyMetaData.ProjectName.id] = value;
    }

    public get unitLength() {
        return this.model[PropertyMetaData.Option_UnitLength.id] as Unit;
    }
    public set unitLength(value) {
        this.model[PropertyMetaData.Option_UnitLength.id] = value ?? null;
    }

    public get unitLengthLarge() {
        return this.model[PropertyMetaData.Option_UnitLengthLarge.id] as Unit;
    }
    public set unitLengthLarge(value) {
        this.model[PropertyMetaData.Option_UnitLengthLarge.id] = value ?? null;
    }

    public get unitArea() {
        return this.model[PropertyMetaData.Option_UnitArea.id] as Unit;
    }
    public set unitArea(value) {
        this.model[PropertyMetaData.Option_UnitArea.id] = value ?? null;
    }

    public get unitStress() {
        return this.model[PropertyMetaData.Option_UnitStress.id] as Unit;
    }
    public set unitStress(value) {
        this.model[PropertyMetaData.Option_UnitStress.id] = value ?? null;
    }

    public get unitStressSmall() {
        return this.model[PropertyMetaData.Option_UnitStressSmall.id] as Unit;
    }
    public set unitStressSmall(value) {
        this.model[PropertyMetaData.Option_UnitStressSmall.id] = value ?? null;
    }

    public get unitForce() {
        return this.model[PropertyMetaData.Option_UnitForce.id] as Unit;
    }
    public set unitForce(value) {
        this.model[PropertyMetaData.Option_UnitForce.id] = value ?? null;
    }

    public get unitMoment() {
        return this.model[PropertyMetaData.Option_UnitMoment.id] as Unit;
    }
    public set unitMoment(value) {
        this.model[PropertyMetaData.Option_UnitMoment.id] = value ?? null;
    }

    public get unitTemperature() {
        return this.model[PropertyMetaData.Option_UnitTemperature.id] as Unit;
    }
    public set unitTemperature(value) {
        this.model[PropertyMetaData.Option_UnitTemperature.id] = value ?? null;
    }

    public get unitForcePerLength() {
        return this.model[PropertyMetaData.Option_UnitForcePerLength.id] as Unit;
    }
    public set unitForcePerLength(value) {
        this.model[PropertyMetaData.Option_UnitForcePerLength.id] = value ?? null;
    }

    public get unitMomentPerLength() {
        return this.model[PropertyMetaData.Option_UnitMomentPerLength.id] as Unit;
    }
    public set unitMomentPerLength(value) {
        this.model[PropertyMetaData.Option_UnitMomentPerLength.id] = value ?? null;
    }

    public get unitDensity() {
        return this.model[PropertyMetaData.Option_UnitDensity.id] as Unit;
    }
    public set unitDensity(value) {
        this.model[PropertyMetaData.Option_UnitDensity.id] = value ?? null;
    }

    public get unitAreaPerLength() {
        return this.model[PropertyMetaData.Option_UnitAreaPerLength.id] as Unit;
    }
    public set unitAreaPerLength(value) {
        this.model[PropertyMetaData.Option_UnitAreaPerLength.id] = value ?? null;
    }

    public get baseplateSize() {
        return this.model[PropertyMetaData.AnchorPlate_AnchorPlateSize.id] as BaseplateSize;
    }
    public set baseplateSize(value) {
        this.model[PropertyMetaData.AnchorPlate_AnchorPlateSize.id] = value;
    }

    public get isBridge() {
        return this.model[PropertyMetaData.Loads_IsBridge.id] as boolean;
    }
    public set isBridge(value) {
        this.model[PropertyMetaData.Loads_IsBridge.id] = value;
    }

    public get gammaPermanentSup() {
        return this.model[PropertyMetaData.Loads_Gamma_perm_sup.id] as number;
    }
    public set gammaPermanentSup(value) {
        this.model[PropertyMetaData.Loads_Gamma_perm_sup.id] = value;
    }

    public get gammaPermanentInf() {
        return this.model[PropertyMetaData.Loads_Gamma_perm_inf.id] as number;
    }
    public set gammaPermanentInf(value) {
        this.model[PropertyMetaData.Loads_Gamma_perm_inf.id] = value;
    }

    public get gammaVarHBuilding() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_building.id] as number;
    }
    public set gammaVarHBuilding(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_building.id] = value;
    }

    public get gammaVarVBuilding() {
        return this.model[PropertyMetaData.Loads_Gamma_var_v_building.id] as number;
    }
    public set gammaVarVBuilding(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_v_building.id] = value;
    }

    public get gammaVarHBridge() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_bridge.id] as number;
    }
    public set gammaVarHBridge(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_bridge.id] = value;
    }

    public get gammaVarVBridge() {
        return this.model[PropertyMetaData.Loads_Gamma_var_v_bridge.id] as number;
    }
    public set gammaVarVBridge(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_v_bridge.id] = value;
    }

    public get gammaVarHWind() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_wind.id] as number;
    }
    public set gammaVarHWind(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_wind.id] = value;
    }

    public get gammaVarHWindBridge() {
        return this.model[PropertyMetaData.Loads_Gamma_var_h_wind_bridge.id] as number;
    }
    public set gammaVarHWindBridge(value) {
        this.model[PropertyMetaData.Loads_Gamma_var_h_wind_bridge.id] = value;
    }
    public get measureAnchorPlate() {
        return this.model[PropertyMetaData.Option_MeasureAnchorPlate.id] as number;
    }
    public set measureAnchorPlate(value) {
        this.model[PropertyMetaData.Option_MeasureAnchorPlate.id] = value ?? null;
    }
    public get measureBaseMaterialEdgeFrom() {
        return this.model[PropertyMetaData.Option_MeasureBaseMaterialEdgeFrom.id] as number;
    }
    public set measureBaseMaterialEdgeFrom(value) {
        this.model[PropertyMetaData.Option_MeasureBaseMaterialEdgeFrom.id] = value ?? null;
    }
    public get combiPsi0H() {
        return this.model[PropertyMetaData.Loads_Combi_psi_0_h.id] as number;
    }
    public set combiPsi0H(value) {
        this.model[PropertyMetaData.Loads_Combi_psi_0_h.id] = value;
    }

    public get combiPsi0V() {
        return this.model[PropertyMetaData.Loads_Combi_psi_0_v.id] as number;
    }
    public set combiPsi0V(value) {
        this.model[PropertyMetaData.Loads_Combi_psi_0_v.id] = value;
    }

    public get combiPsi0HWind() {
        return this.model[PropertyMetaData.Loads_Combi_psi_0_h_wind.id] as number;
    }
    public set combiPsi0HWind(value) {
        this.model[PropertyMetaData.Loads_Combi_psi_0_h_wind.id] = value;
    }

    public get punctualLoadInWorstPosition() {
        return this.model[PropertyMetaData.Loads_PunctualLoadInWorstPosition.id] as boolean;
    }
    public set punctualLoadInWorstPosition(value) {
        this.model[PropertyMetaData.Loads_PunctualLoadInWorstPosition.id] = value;
    }

    public get verticalPunctualLoadInWorstPosition() {
        return this.model[PropertyMetaData.Loads_VerticalPunctualLoadInWorstPosition.id] as boolean;
    }

    public get isinfillPunctualLoadOutwardsHidden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillPunctualLoadOutwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get isinfillPunctualLoadInwardsHidden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillPunctualLoadInwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get NRk() {
        return this.model[PropertyMetaData.Loads_OnSiteTestsValues_N_Rk.id] as number;
    }
    public set NRk(value: number) {
        this.model[PropertyMetaData.Loads_OnSiteTestsValues_N_Rk.id] = value;
    }

    public get VRk() {
        return this.model[PropertyMetaData.Loads_OnSiteTestsValues_V_Rk.id] as number;
    }
    public set VRk(value: number) {
        this.model[PropertyMetaData.Loads_OnSiteTestsValues_V_Rk.id] = value;
    }

    public get brickGroup() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BrickGroup] as BrickGroup[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.BaseMaterial_BrickGroup.id]);
    }

    public get brickSize() {
        return this.model[PropertyMetaData.BaseMaterial_BrickSize.id] as BrickSize;
    }
    public set brickSize(value) {
        this.model[PropertyMetaData.BaseMaterial_BrickSize.id] = value;
    }

    public get shearLoadDistributionType() {
        return this.model[PropertyMetaData.Loads_ShearLoadDistributionType.id] as number;
    }

    public set shearLoadDistributionType(value) {
        this.model[PropertyMetaData.Loads_ShearLoadDistributionType.id] = value;
    }

    public get calculationType() {
        return this.model[PropertyMetaData.AnchorPlate_CalculationType.id] as AdvancedCalculationType;
    }
    public set calculationType(value) {
        this.model[PropertyMetaData.AnchorPlate_CalculationType.id] = value;
    }

    public get stainlessSteel() {
        return this.model[PropertyMetaData.Option_StainlessSteel.id] as boolean;
    }
    public set stainlessSteel(value) {
        this.model[PropertyMetaData.Option_StainlessSteel.id] = value;
    }

    public get weldOnWebs() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_WebWeldLocation.id] != WeldLocation.None;
    }

    public get weldOnFlanges() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_FlangeWeldLocation.id] != WeldLocation.None;
    }

    public get directWelds() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_DirectWeldLocation.id] != WeldLocation.None;
    }

    public get weldOnStiffeners() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldLocation.id] != null && this.model[PropertyMetaData.AnchorPlate_StiffenerWeldLocation.id] != WeldLocation.None;
    }

    public get webWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldLocation.id] as WeldLocation;
    }

    public get flangeWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldLocation.id] as WeldLocation;
    }

    public get directWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldLocation.id] as WeldLocation;
    }

    public get directWeldLength() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldLength.id] as number;
    }

    public get webWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldThickness.id] as number;
    }

    public get flangeWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldThickness.id] as number;
    }

    public get directWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldThickness.id] as number;
    }

    public get webWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_WebWeldMaterial.id] as number;
    }

    public get flangeWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_FlangeWeldMaterial.id] as number;
    }

    public get directWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_DirectWeldMaterial.id] as number;
    }

    public get stiffenerLayout() {
        const codelist = this.designData.designCodeLists[DesignCodeList.StiffenerLayout] as StiffenerLayout[];

        return codelist?.find((item) => item.id == this.model[PropertyMetaData.AnchorPlate_StiffenerLayout.id]);
    }

    public get isCustomStiffenerLayout() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerLayout.id] == 0;
    }

    public get isStiffenerPresent() {
        if (!this.isCBFEMCalculation) {
            return false;
        }

        if (this.isCustomStiffenerLayout && this.customStiffeners != null && this.customStiffeners.length > 0) {
            return true;
        }

        if (this.stiffenerLayout != null && this.stiffenerLayout.stiffenerLocation != StiffenerLocation.None) {
            return true;
        }

        return false;
    }

    public get stiffenerRadialOffset() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerRadialOffset.id] as number;
    }

    public get stiffenerWeldMaterial() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldMaterial.id] as number;
    }

    public get stiffenerHeight() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerHeight.id] as number;
    }

    public get stiffenerWidth() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWidth.id] as number;
    }

    public get stiffenerThickness() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerThickness.id] as number;
    }

    public get stiffenerHorizontalEdgeLength() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerHorizontalEdgeLength.id] as number;
    }

    public get stiffenerVerticalEdgeLength() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerVerticalEdgeLength.id] as number;
    }

    public get stiffenerWeldThickness() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldThickness.id] as number;
    }

    public get stiffenerWeldLocation() {
        return this.model[PropertyMetaData.AnchorPlate_StiffenerWeldLocation.id] as WeldLocation;
    }

    public get profileSize() {
        const codelist = this.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];

        return codelist.find((item) => item.id == this.model[PropertyMetaData.Profile_ProfileSizeId.id]);
    }

    public get profileThickness() {
        return this.model[PropertyMetaData.Profile_CustomThickness.id] as number;
    }

    public get profileAngle() {
        return this.model[PropertyMetaData.Profile_Angle.id] as number;
    }

    public get profileOffsetX() {
        return this.model[PropertyMetaData.Profile_OffsetX.id] as number;
    }

    public get profileOffsetY() {
        return this.model[PropertyMetaData.Profile_OffsetY.id] as number;
    }

    public get handrailPostProfileFamilyId() {
        return this.model[PropertyMetaData.Profile_HandrailPostProfileFamilyId.id];
    }

    public get showAllLoads() {
        return this.model[PropertyMetaData.Loads_ShowAllLoads.id] as boolean;
    }

    public get isFatigueExpertMode() {
        return this.model[PropertyMetaData.Loads_IsFatigueExpertMode.id] as boolean;
    }
    public set isFatigueExpertMode(value) {
        this.model[PropertyMetaData.Loads_IsFatigueExpertMode.id] = value;
    }

    public get isAnchorENFatigueCompliant() {
        return this.model[PropertyMetaData.AnchorLayout_IsAnchorENFatigueCompliant.id] as boolean;
    }

    public get isDynamicFatigue() {
        const eurocodeAllowed =
            (
                this.designStandard.id == DesignStandardEnum.EC2
                || this.designStandard.id == DesignStandardEnum.NZ
                || this.designStandard.id == DesignStandardEnum.IS
                || this.designStandard.id == DesignStandardEnum.MS
            ) &&
            (
                this.designMethodGroup.id == DesignMethodGroupEnum.EN_Based ||
                this.designMethodGroup.id == DesignMethodGroupEnum.SOFA_Based ||
                (
                    this.designMethodGroup.id == DesignMethodGroupEnum.ETA_Based
                    && this.isAnchorENFatigueCompliant
                )
            );

        const hongKongAllowed = this.designStandard.id == DesignStandardEnum.HK &&
            (
                this.designMethodGroup.id == DesignMethodGroupEnum.EN_AnchorDesign ||
                this.designMethodGroup.id == DesignMethodGroupEnum.SOFA_Based
            );

        return this.designType.id == DesignTypeId.Concrete
            && this.isFatigueDesign
            && (eurocodeAllowed || hongKongAllowed);
    }

    public get windZone() {
        const codeList = this.designData.designCodeLists[DesignCodeList.WindZone] as WindZone[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.Application_WindZone.id]);
    }
    public set windZone(value) {
        this.model[PropertyMetaData.Application_WindZone.id] = value != null ? value.id : null;
    }

    public get buildingWidth() {
        return this.model[PropertyMetaData.Application_BuildingWidth.id] as number;
    }
    public set buildingWidth(value) {
        this.model[PropertyMetaData.Application_BuildingWidth.id] = value;
    }

    public get buildingHeight() {
        return this.model[PropertyMetaData.Application_BuildingHeight.id] as number;
    }
    public set buildingHeight(value) {
        this.model[PropertyMetaData.Application_BuildingHeight.id] = value;
    }

    public get buildingDepth() {
        return this.model[PropertyMetaData.Application_BuildingDepth.id] as number;
    }
    public set buildingDepth(value) {
        this.model[PropertyMetaData.Application_BuildingDepth.id] = value;
    }

    public get buildingZone() {
        const codeList = this.designData.designCodeLists[DesignCodeList.BuildingArea] as BuildingArea[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.Application_BuildingZone.id]);
    }
    public set buildingZone(value) {
        this.model[PropertyMetaData.Application_BuildingZone.id] = value != null ? value.id : null;
    }

    public get isFreeStandingWall() {
        const item = this.model[PropertyMetaData.Application_IsFreeStandingWall.id];
        return item !== undefined ? item as boolean : true;
    }
    public set isFreeStandingWall(value) {
        this.model[PropertyMetaData.Application_IsFreeStandingWall.id] = value;
    }

    public get handrailLength() {
        return this.model[PropertyMetaData.Application_HandrailLength.id] as number;
    }
    public set handrailLength(value) {
        this.model[PropertyMetaData.Application_HandrailLength.id] = value;
    }

    public get handrailHeightOverGround() {
        return this.model[PropertyMetaData.Application_HandrailHeightOverGround.id] as number;
    }
    public set handrailHeightOverGround(value) {
        this.model[PropertyMetaData.Application_HandrailHeightOverGround.id] = value;
    }

    public get stairWidth() {
        return this.model[PropertyMetaData.BaseMaterial_StairLength.id] as number;
    }
    public get stairHeight() {
        return this.model[PropertyMetaData.BaseMaterial_StairHeight.id] as number;
    }

    public get altitude() {
        return this.model[PropertyMetaData.Application_Altitude.id] as number;
    }
    public set altitude(value) {
        this.model[PropertyMetaData.Application_Altitude.id] = value;
    }

    public get distanceToSeaside() {
        return this.model[PropertyMetaData.Application_DistanceToSeaside.id] as number;
    }
    public set distanceToSeaside(value) {
        this.model[PropertyMetaData.Application_DistanceToSeaside.id] = value;
    }

    public get referenceVelocityPressure() {
        return this.model[PropertyMetaData.Application_WindVelocityPressure.id] as number;
    }
    public set referenceVelocityPressure(value) {
        this.model[PropertyMetaData.Application_WindVelocityPressure.id] = value;
    }

    public get fundamentalWindVelocity() {
        return this.model[PropertyMetaData.Loads_FundamentalWindVelocity.id] as number;
    }
    public set fundamentalWindVelocity(value) {
        this.model[PropertyMetaData.Loads_FundamentalWindVelocity.id] = value;
    }

    public get windPressure() {
        return this.model[PropertyMetaData.Application_WindPressure.id] as number;
    }
    public set windPressure(value) {
        this.model[PropertyMetaData.Application_WindPressure.id] = value;
    }

    public get windUplift() {
        return this.model[PropertyMetaData.Application_WindUplift.id] as number;
    }
    public set windUplift(value) {
        this.model[PropertyMetaData.Application_WindUplift.id] = value;
    }

    public get terrainCategoryId() {
        return this.model[PropertyMetaData.Application_TerrainCategoryId.id] as number;
    }
    public set terrainCategoryId(value) {
        this.model[PropertyMetaData.Application_TerrainCategoryId.id] = value;
    }

    public get windLoadStateId() {
        const cityId = this.model[PropertyMetaData.Application_CityAndStateId.id] as number;
        const selectedCity = this.designData.designCodeLists[DesignCodeList.WindLoadCity].find((item) => item.id == cityId);
        const selectedState = this.designData.designCodeLists[DesignCodeList.WindLoadState].find((item) => item.name == (selectedCity as WindLoadCity).federalState);
        return selectedState != null && selectedState != undefined ? selectedState.id : null;
    }

    public get windLoadCityId() {
        return this.model[PropertyMetaData.Application_CityAndStateId.id] as number;
    }
    public set windLoadCityId(value) {
        this.model[PropertyMetaData.Application_CityAndStateId.id] = value;
    }

    public get zipCodeHandrailLoadsId() {
        return this.model[PropertyMetaData.Application_ZipCodeHandrailLoadsId.id] as number;
    }
    public set zipCodeHandrailLoadsId(value) {
        this.model[PropertyMetaData.Application_ZipCodeHandrailLoadsId.id] = value;
    }

    public get roughnessClassId() {
        return this.model[PropertyMetaData.Application_RoughnessClassId.id] as number;
    }
    public set roughnessClassId(value) {
        this.model[PropertyMetaData.Application_RoughnessClassId.id] = value;
    }

    public get returnPeriod() {
        return this.model[PropertyMetaData.Application_ReturnPeriod.id] as number;
    }
    public set returnPeriod(value) {
        this.model[PropertyMetaData.Application_ReturnPeriod.id] = value;
    }

    public get distanceUpwindToShorelineId() {
        return this.model[PropertyMetaData.Application_DistanceUpwindToShorelineId.id] as number;
    }
    public set distanceUpwindToShorelineId(value) {
        this.model[PropertyMetaData.Application_DistanceUpwindToShorelineId.id] = value;
    }

    public get distanceInsideTownTerrainId() {
        return this.model[PropertyMetaData.Application_DistanceInsideTownTerrainId.id] as number;
    }
    public set distanceInsideTownTerrainId(value) {
        this.model[PropertyMetaData.Application_DistanceInsideTownTerrainId.id] = value;
    }

    public get basicWindVelocityId() {
        return this.model[PropertyMetaData.Application_BasicWindVelocityId.id] as number;
    }
    public set basicWindVelocityId(value) {
        this.model[PropertyMetaData.Application_BasicWindVelocityId.id] = value;
    }

    public get referenceWindVelocityId() {
        return this.model[PropertyMetaData.Application_ReferenceWindVelocityId.id] as number;
    }
    public set referenceWindVelocityId(value) {
        this.model[PropertyMetaData.Application_ReferenceWindVelocityId.id] = value;
    }

    public get terrainTypeId() {
        return this.model[PropertyMetaData.Application_TerrainType.id] as number;
    }
    public set terrainTypeId(value) {
        this.model[PropertyMetaData.Application_TerrainType.id] = value;
    }

    public get isOrographyRelevant() {
        return this.model[PropertyMetaData.Application_IsOrographyRelevant.id] as boolean;
    }
    public set isOrographyRelevant(value) {
        this.model[PropertyMetaData.Application_IsOrographyRelevant.id] = value;
    }

    public get orographyInputH() {
        return this.model[PropertyMetaData.Application_H.id] as number;
    }
    public set orographyInputH(value) {
        this.model[PropertyMetaData.Application_H.id] = value;
    }

    public get orographyInputX() {
        return this.model[PropertyMetaData.Application_X.id] as number;
    }
    public set orographyInputX(value) {
        this.model[PropertyMetaData.Application_X.id] = value;
    }

    public get orographyInputLu() {
        return this.model[PropertyMetaData.Application_Lu.id] as number;
    }
    public set orographyInputLu(value) {
        this.model[PropertyMetaData.Application_Lu.id] = value;
    }

    public get orographyInputLd() {
        return this.model[PropertyMetaData.Application_Ld.id] as number;
    }
    public set orographyInputLd(value) {
        this.model[PropertyMetaData.Application_Ld.id] = value;
    }

    public get beamLength() {
        return this.model[PropertyMetaData.BaseMaterial_BeamLength.id] as number;
    }

    public get balustradeHeight() {
        const codelist = this.designData.designCodeLists[DesignCodeList.HandrailApplication] as HandrailApplicationTypeEntity[];
        const application = codelist.find((application) => application.id == this.model[PropertyMetaData.Application_Application.id]);

        if (application.handrailApplicationType != HandrailApplicationType.Balustrade) {
            return 0;
        }

        return this.model[PropertyMetaData.BaseMaterial_BalustradeHeight.id] as number;
    }

    public get anchorType() {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.designMethodGroup.id)
            ? this.designData.designCodeLists[DesignCodeList.AnchorTypeAC58] as AnchorTypeEntity[]
            : this.designData.designCodeLists[DesignCodeList.AnchorType] as AnchorTypeEntity[];

        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorLayout_Type.id]);
    }

    public get anchorSize() {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.designMethodGroup.id)
            ? this.designData.designCodeLists[DesignCodeList.AnchorSizeAC58] as AnchorSizeEntity[]
            : this.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSizeEntity[];

        return codeList?.find((item) => item.id == this.model[PropertyMetaData.AnchorLayout_Size.id]);
    }

    public get metalDeckType() {
        return this.model[PropertyMetaData.BaseMaterial_MetalDeckType.id] as number;
    }

    public get anchorFamily() {
        const codeList = this.designData.designCodeLists[this.globalMetaProperties.productCodeListProperty] as AnchorFamily[];
        return codeList.find((item) => item.id == this.model[this.globalMetaProperties.sourceMetaProperty]);
    }

    public get concreteType() {
        return this.model[PropertyMetaData.BaseMaterial_ConcreteType.id] as ConcreteType;
    }

    public get isConcreteTypeSFRC() {
        return this.concreteType == ConcreteType.SteelFiberReinforcedConcrete;
    }

    public get concreteCharacteristicValue() {
        return this.model[PropertyMetaData.BaseMaterial_ConcreteCharacteristic.id] as ConcreteCharacteristic;
    }
    public set concreteCharacteristicValue(value) {
        this.model[PropertyMetaData.BaseMaterial_ConcreteCharacteristic.id] = value;
    }

    public get supplementaryReinforcementCategory() {
        return this.model[PropertyMetaData.SupplementaryReinforcement_ReinforcementCategory.id] as SupplementaryReinforcementCategoryEnum;
    }

    public get anchorPlateExists() {
        return this.model[PropertyMetaData.AnchorPlate_PlateShape.id] != PlateShape.None;
    }

    public get isShearLugPresent() {
        return this.model[PropertyMetaData.Profile_UseShearLug.id] as boolean;
    }

    public get profileExists() {
        return this.model[PropertyMetaData.Profile_UseProfile.id] as boolean;
    }

    public get IsAnchorPlatePresent() {
        return this.model[PropertyMetaData.AnchorPlate_Exists.id];
    }

    public get handrailRailProfileFamily() {
        const codelist = this.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];

        return codelist.find((item) => item.id == this.model[PropertyMetaData.Profile_HandrailRailProfileFamilyId.id]);
    }

    public get newAndImportLoadDisabled() {
        return this.model[PropertyMetaData.Loads_NewAndImportLoad.id] as boolean;
    }

    public get concreteCharacteristic() {
        const codeList = this.designData.designCodeLists[DesignCodeList.ConcreteCharacteristic] as ConcreteCharacteristicEntity[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.BaseMaterial_ConcreteCharacteristic.id]);
    }

    public get concreteGrade() {
        const codeList = this.designData.designCodeLists[DesignCodeList.ConcreteGrade] as ConcreteGrade[];

        return codeList.find((item) => item.id == this.model[PropertyMetaData.BaseMaterial_ConcreteGrade.id]);
    }

    public get customCompressiveStrength() {
        return this.model[PropertyMetaData.BaseMaterial_CustomCompressiveStrength.id] as number;
    }

    public get hasIntegratedProfile() {
        return this.isLedgerAngle;
    }

    public get standOffNoneIfNull() {
        const codeList = this.designData.designCodeLists[DesignCodeList.StandOff] as StandOff[];
        const item = codeList.find((item) => item.id == this.model[PropertyMetaData.AnchorPlate_StandoffType.id]);
        return item ?? codeList.find((item) => item.id == StandOffType.None);
    }

    public get standOffDistance() {
        return this.model[PropertyMetaData.AnchorPlate_StandoffDistance.id] as number;
    }

    public get noLoads() {
        return (
            (this.forceX == null || this.forceX == 0) &&
            (this.forceY == null || this.forceY == 0) &&
            (this.forceZ == null || this.forceZ == 0) &&
            (this.momentX == null || this.momentX == 0) &&
            (this.momentY == null || this.momentY == 0) &&
            (this.momentZ == null || this.momentZ == 0)
        ) as boolean;
    }

    public get isAnchorReinforcement() {
        return this.model[PropertyMetaData.BaseMaterial_IsAnchorReinforcement.id] as boolean;
    }
    public set isAnchorReinforcement(value) {
        this.model[PropertyMetaData.BaseMaterial_IsAnchorReinforcement.id] = value;
    }

    public get filledHolesSOFA() {
        return this.model[PropertyMetaData.AnchorLayout_FillHolesSOFA.id] as boolean;
    }
    public set filledHolesSOFA(value) {
        this.model[PropertyMetaData.AnchorLayout_FillHolesSOFA.id] = value;
    }

    public get filledHolesETAG() {
        return this.model[PropertyMetaData.AnchorLayout_FillHolesETAG.id] as boolean;
    }

    public get isLedgerAngle() {
        return (this.model[PropertyMetaData.AnchorPlate_LedgerAngleShape.id] as PlateShape) == PlateShape.LedgerAngle;
    }

    public get isWHGConcreteWithCoating() {
        return this.model[PropertyMetaData.BaseMaterial_WHGType.id] == WHGTypeEnum.Coating;
    }

    public get anchorPlateShape() {
        return this.model[PropertyMetaData.AnchorPlate_PlateShape.id] as PlateShape;
    }
    public set anchorPlateShape(value) {
        this.model[PropertyMetaData.AnchorPlate_PlateShape.id] = value;
    }

    public get anchorLayout() {
        return this.model[PropertyMetaData.AnchorLayout_Layout.id] as number;
    }
    public set anchorLayout(value) {
        this.model[PropertyMetaData.AnchorLayout_Layout.id] = value;
    }

    public get anchorLayoutSymmetry() {
        return this.model[PropertyMetaData.AnchorLayout_AnchorLayoutSymmetry.id] as AnchorLayoutSymmetry;
    }
    public set anchorLayoutSymmetry(value) {
        this.model[PropertyMetaData.AnchorLayout_AnchorLayoutSymmetry.id] = value;
    }

    public get showPostAndRailDeflection() {
        return this.model[PropertyMetaData.Application_CalculatePostAndRailDeflection.id] as number;
    }

    public get showOptimizedAnchorPlateThickness() {
        return this.model[PropertyMetaData.AnchorPlate_ShowOptimizedAnchorPlateThickness.id] as boolean;
    }

    public get concreteEtagEnOnly() {
        return this.model[PropertyMetaData.Loads_ConcreteEtagEnOnly.id] as boolean;
    }
    public set concreteEtagEnOnly(value) {
        this.model[PropertyMetaData.Loads_ConcreteEtagEnOnly.id] = value;
    }

    public get handrailEtagEnOnly() {
        return this.model[PropertyMetaData.Loads_HandrailEtagEnOnly.id] as boolean;
    }
    public set handrailEtagEnOnly(value) {
        this.model[PropertyMetaData.Loads_HandrailEtagEnOnly.id] = value;
    }

    public get jointThicknessHorizontal() {
        return this.model[PropertyMetaData.BaseMaterial_JointThicknessHorizontal.id] as number;
    }

    public get jointThicknessVertical() {
        return this.model[PropertyMetaData.BaseMaterial_JointThicknessVertical.id] as number;
    }

    public get punctualRailLoadOutwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadRailOutwards.id] as number;
    }

    public get punctualRailLoadInwards() {
        return this.model[PropertyMetaData.Loads_PunctualLoadRailInwards.id] as number;
    }

    public get isInfillLoadInwardsHidden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillLoadInwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get isInfillLoadOutwardsHIdden() {
        const item = this.properties.properties[PropertyMetaData.Loads_InfillLoadOutwards.id];
        return item !== undefined ? item.hidden : true;
    }

    public get showStressDistribution() {
        return this.model[PropertyMetaData.AnchorPlate_ShowStressDistribution.id] as boolean;
    }

    public get showWeldDesign() {
        return this.model[PropertyMetaData.Profile_ShowWeldDesign.id] as boolean;
    }

    public get buildingCategory() {
        const codelist = this.designData.designCodeLists[DesignCodeList.BuildingCategory] as BuildingCategory[];

        return codelist.find((item) => item.id == this.model[PropertyMetaData.Application_BuildingCategory.id]);
    }

    public get anchorPlateStressImage() {
        return this.model[PropertyMetaData.AnchorPlate_StressImage.id] as string;
    }

    public get safetyFactorForPermamentLoads() {
        return this.model[PropertyMetaData.Option_SafetyFactorForPermamentLoads.id] as number;
    }
    public set safetyFactorForPermamentLoads(value) {
        this.model[PropertyMetaData.Option_SafetyFactorForPermamentLoads.id] = value ?? null;
    }

    public get safetyFactorForVariableLoads() {
        return this.model[PropertyMetaData.Option_SafetyFactorForVariableLoads.id] as number;
    }
    public set safetyFactorForVariableLoads(value) {
        this.model[PropertyMetaData.Option_SafetyFactorForVariableLoads.id] = value ?? null;
    }

    public get loadsCalculationMode() {
        return this.model[PropertyMetaData.Loads_LoadCombinationsCalculationType.id] as LoadCombinationsCalculationType;
    }
    public set loadsCalculationMode(value) {
        this.model[PropertyMetaData.Loads_LoadCombinationsCalculationType.id] = value ?? null;
    }

    public get minimumAnchorToProfileDistance() {
        return this.model[PropertyMetaData.Option_MinimumAnchorToProfileDistance.id] as number;
    }
    public set minimumAnchorToProfileDistance(value) {
        this.model[PropertyMetaData.Option_MinimumAnchorToProfileDistance.id] = value ?? null;
    }

    public get minimumConcreteCover() {
        return this.model[PropertyMetaData.Option_MinimumConcreteCover.id] as number;
    }
    public set minimumConcreteCover(value) {
        this.model[PropertyMetaData.Option_MinimumConcreteCover.id] = value ?? null;
    }

    public get concreteSafetyFactorGammaC() {
        return this.model[PropertyMetaData.Option_ConcreteSafetyFactorGammaC.id] as ConcreteSafetyFactorGammaC;
    }
    public set concreteSafetyFactorGammaC(value) {
        this.model[PropertyMetaData.Option_ConcreteSafetyFactorGammaC.id] = value ?? null;
    }

    public get handrailDisplacementLimit() {
        return this.model[PropertyMetaData.Option_HandrailDisplacementLimit.id] as number;
    }
    public set handrailDisplacementLimit(value) {
        this.model[PropertyMetaData.Option_HandrailDisplacementLimit.id] = value ?? null;
    }

    public get useOnSiteTestsData() {
        return this.model[PropertyMetaData.Loads_UseOnSiteTestsData.id] as boolean;
    }
    public set useOnSiteTestsData(value) {
        this.model[PropertyMetaData.Loads_UseOnSiteTestsData.id] = value ?? null;
    }

    public get ostInputsData() {
        return this.model[PropertyMetaData.Option_OstInputsEnabled.id] as boolean;
    }
    public set ostInputsData(value) {
        this.model[PropertyMetaData.Option_OstInputsEnabled.id] = value ?? null;

        const useOnSiteVal = this.useOnSiteTestsData && this.ostInputsData;
        this.useOnSiteTestsData = useOnSiteVal;
    }

    public get designMethodGroupsAvailable() {
        let designMethodGroups = (this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[])
            .filter((designMethodGroup) => designMethodGroup.designStandards.includes(this.designStandard.id));

        // filter design method groups by design type and region
        designMethodGroups = designMethodGroups.filter((designMethodGroup) => this.designType.allowedDesignMethodGroupRegions.some((row) => row.RegionId == this.region.id && row.DesignMethodGroups.includes(designMethodGroup.id)));

        return designMethodGroups;
    }

    public get steelSafetyFactorGammaM0() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM0.id] as number;
    }
    public set steelSafetyFactorGammaM0(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM0.id] = value;
    }

    public get steelSafetyFactorGammaM1() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM1.id] as number;
    }
    public set steelSafetyFactorGammaM1(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM1.id] = value;
    }

    public get steelSafetyFactorGammaM2() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM2.id] as number;
    }
    public set steelSafetyFactorGammaM2(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM2.id] = value;
    }

    public get steelSafetyFactorGammaM5() {
        return this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM5.id] as number;
    }
    public set steelSafetyFactorGammaM5(value) {
        this.model[PropertyMetaData.Option_SteelSafetyFactorGammaM5.id] = value;
    }

    public get materialSafetyFactor() {
        return this.model[PropertyMetaData.Option_MaterialSafetyFactor.id] as number;
    }
    public set materialSafetyFactor(value) {
        this.model[PropertyMetaData.Option_MaterialSafetyFactor.id] = value;
    }

    public get handrailConnectionType() {
        return this.model[PropertyMetaData.Application_ConnectionType.id] as HandrailConnectionType;
    }

    public get weldCapacityFactor() {
        return this.model[PropertyMetaData.Option_WeldsCapacityFactor.id] as number;
    }
    public set weldCapacityFactor(value) {
        this.model[PropertyMetaData.Option_WeldsCapacityFactor.id] = value;
    }

    public get concreteCapacityFactor() {
        return this.model[PropertyMetaData.Option_ConcreteCapacityFactor.id] as number;
    }
    public set concreteCapacityFactor(value) {
        this.model[PropertyMetaData.Option_ConcreteCapacityFactor.id] = value;
    }

    public get jointCoefficientBj() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_JointCoefficientBj.id] as number;
    }
    public set jointCoefficientBj(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_JointCoefficientBj.id] = value;
    }

    public get effectiveArea() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveArea.id] as number;
    }
    public set effectiveArea(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveArea.id] = value;
    }

    public get effectiveAreaAISC() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveAreaAISC.id] as number;
    }
    public set effectiveAreaAISC(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_EffectiveAreaAISC.id] = value;
    }

    public get loadDistributionAngle() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_LoadDistributionAngle.id] as number;
    }
    public set loadDistributionAngle(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_LoadDistributionAngle.id] = value;
    }

    public get alphaCC() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_AlphaCC.id] as number;
    }
    public set alphaCC(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_AlphaCC.id] = value;
    }

    public get concreteInCompressionMethod() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_ConcreteInCompressionMethod.id] as ConcreteInCompressionMethod;
    }
    public set concreteInCompressionMethod(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_ConcreteInCompressionMethod.id] = value;
    }

    public get limitPlasticStrain() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_LimitPlasticStrain.id] as number;
    }
    public set limitPlasticStrain(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_LimitPlasticStrain.id] = value;
    }

    public get divisionOfSurfaceOfCHS() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS.id] as number;
    }
    public set divisionOfSurfaceOfCHS(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS.id] = value;
    }

    public get numberOfElementsOfEdge() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfElementsOfEdge.id] as number;
    }
    public set numberOfElementsOfEdge(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfElementsOfEdge.id] = value;
    }

    public get numberOfAnalysisIterations() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfAnalysisIterations.id] as number;
    }
    public set numberOfAnalysisIterations(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_NumberOfAnalysisIterations.id] = value;
    }

    public get divergentIterationsCount() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivergentIterationsCount.id] as number;
    }
    public set divergentIterationsCount(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_DivergentIterationsCount.id] = value;
    }

    public get minimumSizeOfElement() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_MinimumSizeOfElement.id] as number;
    }
    public set minimumSizeOfElement(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_MinimumSizeOfElement.id] = value;
    }

    public get maximumSizeOfElement() {
        return this.model[PropertyMetaData.AdvancedBaseplateCalculation_MaximumSizeOfElement.id] as number;
    }
    public set maximumSizeOfElement(value) {
        this.model[PropertyMetaData.AdvancedBaseplateCalculation_MaximumSizeOfElement.id] = value;
    }

    public get steelCapacityFactor() {
        return this.model[PropertyMetaData.Option_SteelCapacityFactor.id] as number;
    }
    public set steelCapacityFactor(value) {
        this.model[PropertyMetaData.Option_SteelCapacityFactor.id] = value;
    }

    public get inSteelSafetyFactorGammaM0() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM0.id] as number;
    }
    public set inSteelSafetyFactorGammaM0(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM0.id] = value;
    }

    public get inSteelSafetyFactorGammaM1() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM1.id] as number;
    }
    public set inSteelSafetyFactorGammaM1(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaM1.id] = value;
    }

    public get inSteelSafetyFactorGammaMw() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMw.id] as number;
    }
    public set inSteelSafetyFactorGammaMw(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMw.id] = value;
    }

    public get inSteelSafetyFactorGammaMb() {
        return this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMb.id] as number;
    }
    public set inSteelSafetyFactorGammaMb(value) {
        this.model[PropertyMetaData.Option_INSteelSafetyFactorGammaMb.id] = value;
    }
    // Already moved to DesignPe

    // Already moved to DesignC2C
    public get reportLoadCombinationC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ReportLoadCombinationId.id] as string;
    }
    public set reportLoadCombinationC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ReportLoadCombinationId.id] = value;
    }

    public get noteLoadCaseResultingRebarForcesC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_NoteLoadCaseResultingRebarForces.id] as string;
    }
    public set noteLoadCaseResultingRebarForcesC2C(val: string) {
        this.model[PropertyMetaDataC2C.Option_C2C_NoteLoadCaseResultingRebarForces.id] = val;
    }

    public get reportLanguageId() {
        if (this.isC2C) {
            return this.model[PropertyMetaDataC2C.Option_C2C_ReportLanguageId.id] as number;
        }
        return this.model[PropertyMetaData.Option_ReportLanguageLcid.id] as number;
    }
    public set reportLanguageId(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.Option_C2C_ReportLanguageId.id] = value;
            return;
        }
        this.model[PropertyMetaData.Option_ReportLanguageLcid.id] = value;
    }

    public get shearDesignMethods() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_ShearDesignMethods.id] as DesignMethod;
    }
    public set shearDesignMethods(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_ShearDesignMethods.id] = value;
    }

    public get customPicturesC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_CustomPictures.id] as string[];
    }
    public set customPicturesC2C(val: string[]) {
        this.model[PropertyMetaDataC2C.Option_C2C_CustomPictures.id] = val;
    }

    public get displayAnchorTheoryC2C() {
        return (this.model[PropertyMetaDataC2C.AnchorTheory_C2C_IsAnchorTheoryDisplayed.id] || this.model[PropertyMetaDataC2C.Loads_C2C_IsAnchorTheoryDisplayed.id]) as boolean;
    }
    public set displayAnchorTheoryC2C(value: boolean) {
        if (this.isC2COverlay) {
            this.displayAnchorTheoryC2COverlay = value;
        }
        else {
            this.displayAnchorTheoryC2CNonOverlay = value;
        }
    }

    private set displayAnchorTheoryC2COverlay(value: boolean) {
        this.model[PropertyMetaDataC2C.AnchorTheory_C2C_IsAnchorTheoryDisplayed.id] = value;
    }

    private set displayAnchorTheoryC2CNonOverlay(value: boolean) {
        this.model[PropertyMetaDataC2C.Loads_C2C_IsAnchorTheoryDisplayed.id] = value;
    }

    public get isC2CHNA() {
        return this.isC2C ? isHnaBasedDesignStandard(this.designStandard.id) : false;
    }

    public get isPostInstalledRebarDesign() {
        return this.isC2C && this.connectionType == ConnectionType.Splices || this.connectionType == ConnectionType.StructuralJoints;
    }

    public get isPirEu(): boolean {
        return this.isPostInstalledRebarDesign && (this.designStandardC2C.id == DesignStandardEnumC2C.ETAG || this.isAusEN);
    }

    public get isPirEuOrAus(): boolean {
        return this.isPirAus || this.isPirEu;
    }

    public get isPirAus(): boolean {
        return this.isPostInstalledRebarDesign && this.designStandardC2C?.id == DesignStandardEnumC2C.ASBased;
    }

    public get isAusEN(): boolean {
        return (this.designStandardC2C?.id == DesignStandardEnumC2C.ASBased && this.designMethodGroup?.id == DesignMethodGroupEnumC2C.EN199211);
    }

    public get isPirASAS(): boolean {
        return this.isPirAus && this.designMethodGroup?.id == DesignMethodGroupEnumC2C.AS3600ETA;
    }

    public get isPirHna(): boolean {
        return this.isC2CHNA && this.isPostInstalledRebarDesign;
    }

    public get isC2COverlay(): boolean {
        return this.isC2C && this.connectionType == ConnectionType.ConcreteOverlay;
    }

    public get isC2CJoints(): boolean {
        return this.isC2C && this.connectionType == ConnectionType.StructuralJoints;
    }

    public get connectionType() {
        return this.model[PropertyMetaDataC2C.General_C2C_ConnectionType.id] as ConnectionType;
    }
    public set connectionType(value) {
        if (this.isC2C) {
            this.model[PropertyMetaDataC2C.General_C2C_ConnectionType.id] = value;
        }
    }

    public get designStandardC2C() {
        const codeList = this.codeListC2C.projectCodeListsC2C[ProjectCodeListC2C.DesignStandardsC2C] as DesignStandardC2C[];

        return codeList.find((designStandardC2C) => designStandardC2C.id == this.model[PropertyMetaDataC2C.Option_C2C_DesignStandard.id]);
    }

    public get selectedDesignMethodC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_SelectedDesignMethod.id] as DesignMethod;
    }

    public get isHnaJointsC2C(): boolean {
        return this.isC2CHNA && this.isC2CJoints;
    }

    public get isHnaExtensionsC2C(): boolean {
        return this.isC2CHNA && this.connectionType == ConnectionType.Splices;
    }

    public get isHnaJointsAnchorTheory(): boolean {
        return this.isHnaJointsC2C && this.isHnaAnchoringDesignMethod;
    }

    public get isHnaAnchoringDesignMethod(): boolean {
        const selectedDesignMethod = this.designData.projectDesignC2C.options.selectedDesignMethod;
        return this.isFeatureEnabled('HnaDesignMethods') ?
            selectedDesignMethod == DesignMethod.AnchoringBarYield || selectedDesignMethod == DesignMethod.AnchoringExternalLoads
            : this.displayAnchorTheoryC2C;
    }

    public isFeatureEnabled(featureKey: string) {
        return this.designData.enabledFeatures?.includes(featureKey);
    }

    public get isViewRotated(): boolean {
        if (this.isC2CHNA && this.isC2COverlay)
            return this.model[PropertyMetaDataC2C.Overlay_Rotation_C2C_Angle.id] != 0;
        else if (this.isPostInstalledRebarDesign) {
            const rotation = this.isPirHna ? this.model[PropertyMetaDataC2C.NewStructure_Rotation_C2C_Angle.id] : this.model[PropertyMetaDataC2C.Application_C2C_Connection_Angle.id];
            return rotation != 0;
        }

        return false;
    }

    public get fastenerFamilies() {
        return this.designData.designCodeListsC2C[this.globalMetaProperties.productCodeListProperty] as FastenerFamily[];
    }

    public set fastenerFamilies(value: FastenerFamily[]) {
        this.designData.designCodeListsC2C[this.globalMetaProperties.productCodeListProperty] = value;
    }

    public get rebarFamily() {
        return this.fastenerFamilies.find((item) => item.id == this.model[this.globalMetaProperties.sourceMetaProperty]);
    }

    public get productNameC2C() {
        const rebarType = formatKeyValue(this.designData?.reportDataC2C?.exportReport?.rebarType ?? '', { years: this.localization.getString('Agito.Hilti.C2C.Product.Years') }) ?? this.rebarFamily?.displayName;
        const rebarSize = this.designData?.reportDataC2C?.exportReport?.rebarSize;

        return (rebarType && rebarSize && !this.isPirEuOrAus)
            ? `${rebarType} ${rebarSize}` : rebarType;
    }

    public get fastenerType() {
        const codeListC2C = this.designData.designCodeListsC2C[DesignCodeListC2C.FastenerType] as FastenerType[];
        return codeListC2C?.find((item) => item.id == this.model[PropertyMetaDataC2C.Product_C2C_ConnectorType.id]);
    }

    public get fastenerSize() {
        const codeListC2C = this.designData.designCodeListsC2C[DesignCodeListC2C.FastenerSize] as FastenerSize[];
        return codeListC2C?.find((item) => item.id == this.model[PropertyMetaDataC2C.Product_C2C_ConnectorSize.id]);
    }

    public get isSurfaceTreatmentDisabled(): boolean {
        if (!this.isC2CHNA) {
            return false;
        }

        const propertyId = this.isC2COverlay ? PropertyMetaDataC2C.Overlay_C2C_SurfaceTreatment_SurfaceTreatment.id : PropertyMetaDataC2C.ExistingStructure_C2C_SurfaceTreatment_SurfaceTreatment.id;
        const property = this.properties.get(propertyId);

        return property.disabled;
    }

    public get loadTypeC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_LoadType.id] as LoadTypeC2C;
    }
    public set loadTypeC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_LoadType.id] = value;
    }

    public get loadCombinationsC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinations.id] as LoadCombinationC2C[];
    }
    public set loadCombinationsC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinations.id] = value;
    }

    public get loadsInterfaceShearC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_InterfaceShear.id] as boolean;
    }

    public get loadsZone1C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone1.id] as number;
    }

    public get loadsZone2C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone2.id] as number;
    }

    public get loadsZone3C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone3.id] as number;
    }

    public get loadsZone4C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_Zone4.id] as number;
    }

    public get loadsDynamicZone1C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone1.id] as number;
    }

    public get loadsDynamicZone2C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone2.id] as number;
    }

    public get loadsDynamicZone3C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone3.id] as number;
    }

    public get loadsDynamicZone4C2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_DynamicZone4.id] as number;
    }

    public get selectedLoadCombinationIdC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_SelectedLoadCombinationId.id] as string;
    }
    public set selectedLoadCombinationIdC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_SelectedLoadCombinationId.id] = value;
    }

    public get decisiveLoadCombinationIdC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_DecisiveLoadCombinationId.id] as string;
    }

    public get loadsCalculationModeC2C() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinationsCalculationType.id] as LoadCombinationsCalculationTypeC2C;
    }
    public set loadsCalculationModeC2C(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_LoadCombinationsCalculationType.id] = value ?? null;
    }

    public get partialReductionFactorSteel() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorSteel.id] as number;
    }
    public set partialReductionFactorSteel(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorSteel.id] = value;
    }

    public get partialReductionFactorFire() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorFire.id] as number;
    }
    public set partialReductionFactorFire(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorFire.id] = value;
    }

    public get partialReductionFactorConcrete() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorConcrete.id] as number;
    }
    public set partialReductionFactorConcrete(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialReductionFactorConcrete.id] = value;
    }

    public get alphaCcC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_AlphaCC.id] as number;
    }
    public set alphaCcC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_AlphaCC.id] = value;
    }

    public get alphaCtC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_AlphaCT.id] as number;
    }
    public set alphaCtC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_AlphaCT.id] = value;
    }

    public get advancedRebarSettingsSteelStrainLimitC2C() {
        return this.model[PropertyMetaDataC2C.Option_C2C_AdvancedRebarSettingsSteelStrainLimit.id] as number;
    }
    public set advancedRebarSettingsSteelStrainLimitC2C(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_AdvancedRebarSettingsSteelStrainLimit.id] = value;
    }

    public get k2() {
        return this.model[PropertyMetaDataC2C.Option_C2C_K2.id] as number;
    }
    public set k2(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_K2.id] = value;
    }

    public get epsilonC2() {
        return this.model[PropertyMetaDataC2C.Option_C2C_EpsilonC2.id] as number;
    }
    public set epsilonC2(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_EpsilonC2.id] = value;
    }

    public get epsilonCU2() {
        return this.model[PropertyMetaDataC2C.Option_C2C_EpsilonCU2.id] as number;
    }
    public set epsilonCU2(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_EpsilonCU2.id] = value;
    }

    public get epsilonUD() {
        return this.model[PropertyMetaDataC2C.Option_C2C_EpsilonUD.id] as number;
    }
    public set epsilonUD(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_EpsilonUD.id] = value;
    }

    public get partialSafetyFactorConcrete() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorConcrete.id] as number;
    }
    public set partialSafetyFactorConcrete(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorConcrete.id] = value;
    }

    public get partialSafetyFactorSteel() {
        return this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorSteel.id] as number;
    }
    public set partialSafetyFactorSteel(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_PartialSafetyFactorSteel.id] = value;
    }

    public get minConcreteCover() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MinCoverTop.id] as number;
    }
    public set minConcreteCover(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MinCoverTop.id] = value;
    }

    public get e() {
        return this.model[PropertyMetaDataC2C.Option_C2C_E.id] as number;
    }
    public set e(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_E.id] = value;
    }

    public get existingReinforcementAlpha3() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ExistingReinforcementAlpha3.id] as number;
    }

    public set existingReinforcementAlpha3(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ExistingReinforcementAlpha3.id] = value;
    }

    public get transversePressure() {
        return this.model[PropertyMetaDataC2C.Option_C2C_TransversePressure.id] as number;
    }

    public set transversePressure(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_TransversePressure.id] = value;
    }

    public get minimumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcement.id] as MinimumAreaReinforcement;
    }

    public set minimumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcement.id] = value;
    }

    public get minimumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcementInput.id] as number;
    }

    public set minimumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MinimumAreaReinforcementInput.id] = value;
    }

    public get maximumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcement.id] as MaximumAreaReinforcement;
    }

    public set maximumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcement.id] = value;
    }

    public get maximumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcementInput.id] as number;
    }

    public set maximumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_MaximumAreaReinforcementInput.id] = value;
    }

    public get spacingMaximum() {
        return this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximum.id] as SpacingMaximum;
    }

    public set spacingMaximum(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximum.id] = value;
    }

    public get spacingMaximumInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximumInput.id] as number;
    }

    public set spacingMaximumInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_SpacingMaximumInput.id] = value;
    }

    public get shearReinforcementConcreteMember() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ShearReinforcementConcreteMember.id] as boolean;
    }

    public set shearReinforcementConcreteMember(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ShearReinforcementConcreteMember.id] = value;
    }

    public get shearStrutAngle() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ShearStrutAngle.id] as number;
    }

    public set shearStrutAngle(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ShearStrutAngle.id] = value;
    }

    public get shearInclination() {
        return this.model[PropertyMetaDataC2C.Option_C2C_ShearInclination.id] as number;
    }

    public set shearInclination(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_ShearInclination.id] = value;
    }

    public get strutTies() {
        return this.model[PropertyMetaDataC2C.Option_C2C_StrutTies.id] as StrutTies;
    }

    public set strutTies(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_StrutTies.id] = value;
    }

    public get strutTiesInput() {
        return this.model[PropertyMetaDataC2C.Option_C2C_StrutTiesInput.id] as number;
    }

    public set strutTiesInput(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_StrutTiesInput.id] = value;
    }

    public get optimizationStrategy() {
        return this.model[PropertyMetaDataC2C.Option_C2C_OptimizationStrategy.id] as OptimizationStrategy;
    }

    public set optimizationStrategy(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_OptimizationStrategy.id] = value;
    }

    public get gammaRd() {
        return this.model[PropertyMetaDataC2C.Option_C2C_GammaRd.id] as number;
    }

    public set gammaRd(value) {
        this.model[PropertyMetaDataC2C.Option_C2C_GammaRd.id] = value;
    }

    public get useGermanNationalAnnex() {
        return this.model[PropertyMetaDataC2C.Loads_C2C_UseGermanNationalAnnex.id] as boolean;
    }

    public set useGermanNationalAnnex(value) {
        this.model[PropertyMetaDataC2C.Loads_C2C_UseGermanNationalAnnex.id] = value;
    }

    public get metricEquation(): number {
        return this.model[PropertyMetaDataC2C.Options_C2C_MetricEquation.id] as number;
    }

    public set metricEquation(value: number) {
        this.model[PropertyMetaDataC2C.Options_C2C_MetricEquation.id] = value;
    }

    // Start of ASbased options
    public get AS_epsilonUd() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonUd.id] as number;
    }

    public set AS_epsilonUd(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonUd.id] = value;
    }

    public get AS_steelE() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelE.id] as number;
    }

    public set AS_steelE(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelE.id] = value;
    }

    public get AS_optimizationStrategy() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_OptimizationStrategy.id] as number;
    }

    public set AS_optimizationStrategy(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_OptimizationStrategy.id] = value;
    }

    public get AS_steelStress() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelStressReduction.id] as number;
    }

    public set AS_steelStress(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SteelStressReduction.id] = value;
    }

    public get AS_concreteStressCrossSection() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReduction.id] as number;
    }

    public set AS_concreteStressCrossSection(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReduction.id] = value;
    }

    public get AS_concreteStressShearInterface() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReductionVerification.id] as number;
    }

    public set AS_concreteStressShearInterface(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteStressReductionVerification.id] = value;
    }

    public get AS_epsilonC2() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonC2.id] as number;
    }

    public set AS_epsilonC2(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonC2.id] = value;
    }

    public get AS_epsilonCu2() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonCu2.id] as number;
    }

    public set AS_epsilonCu2(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_EpsilonCu2.id] = value;
    }

    public get AS_beta() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorBeta.id] as number;
    }

    public set AS_beta(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorBeta.id] = value;
    }

    public get AS_minConcreteCover() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteCover.id] as number;
    }

    public set AS_minConcreteCover(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_ConcreteCover.id] = value;
    }

    public get AS_factorK4() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK4.id] as number;
    }

    public set AS_factorK4(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK4.id] = value;
    }

    public get AS_factorK6() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK6.id] as number;
    }

    public set AS_factorK6(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_FactorK6.id] = value;
    }

    public get AS_transversePressure() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_TransversePressure.id] as number;
    }

    public set AS_transversePressure(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_TransversePressure.id] = value;
    }

    public get AS_minimumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcement.id] as number;
    }

    public set AS_minimumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcement.id] = value;
    }

    public get AS_minimumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcementInput.id] as number;
    }

    public set AS_minimumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MinimumAreaReinforcementInput.id] = value;
    }

    public get AS_maximumAreaReinforcement() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcement.id] as number;
    }

    public set AS_maximumAreaReinforcement(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcement.id] = value;
    }

    public get AS_maximumAreaReinforcementInput() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcementInput.id] as number;
    }

    public set AS_maximumAreaReinforcementInput(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_MaximumAreaReinforcementInput.id] = value;
    }

    public get AS_spacingMaximum() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximum.id] as number;
    }

    public set AS_spacingMaximum(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximum.id] = value;
    }

    public get AS_spacingMaximumInput() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximumInput.id] as number;
    }

    public set AS_spacingMaximumInput(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_SpacingMaximumInput.id] = value;
    }

    public get AS_strutAngleNewConcrete() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_StrutAngleNewConcrete.id] as number;
    }

    public set AS_strutAngleNewConcrete(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_StrutAngleNewConcrete.id] = value;
    }

    public get AS_shearTensileAngle() {
        return this.model[PropertyMetaDataC2C.Options_C2C_AS_InclinedShearTensileReinforementAngle.id] as number;
    }

    public set AS_shearTensileAngle(value) {
        this.model[PropertyMetaDataC2C.Options_C2C_AS_InclinedShearTensileReinforementAngle.id] = value;
    }
    // End of ASbased options

    // Already moved to DesignC2C
}
