import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';

import { HttpHeaders, HttpRequest } from '@angular/common/http';
import {
    DraggablePointInfoPe
} from '@profis-engineering/pe-gl-model/components/2d/draggable-point-helper';
import {
    CodeList as CodeListC2C
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/code-list';
import { ICodeListsC2C } from '@profis-engineering/pe-ui-c2c/entities/design-c2c';
import {
    ProjectOpenType as ProjectOpenTypeC2C
} from '@profis-engineering/pe-ui-c2c/entities/tracking-data';
import {
    ConnectionType, DesignStandard as DesignStandardEnumC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    CodeList, ICodeLists
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    CalculationType, DesignEvent, IProperty, IUndoRedoAction, Properties, StateChange,
    UIPropertyTexts
} from '@profis-engineering/pe-ui-common/entities/design';
import { TrackChanges } from '@profis-engineering/pe-ui-common/entities/track-changes';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { trim } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    ICalculateInternalOptionsBase, IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import {
    Change, ChangesServiceBase as ChangesService
} from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    LoggerServiceBase, LogMessage
} from '@profis-engineering/pe-ui-common/services/logger.common';
import { DesignCodeList } from '@profis-engineering/pe-ui-shared/entities/design-code-list';
import {
    CalculationResultEntity as ICalculationResultEntity, GenerateDesignXmlRequest
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    UIPropertyConfig as IUIPropertyConfig
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    ProjectOpenType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import {
    DesignStandard as DesignStandardEnum, DesignType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    GenerateReportRequest
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import {
    PropertyMetaData, UIProperties, UIPropertyId
} from '@profis-engineering/pe-ui-shared/properties/properties';

import { environment } from '../../../environments/environment';
import { Design, IBaseDesign, ICalculationResult, IDesignState } from '../../entities/design';
import { ApiService } from '../api.service';
import { BrowserService } from '../browser.service';
import { DesignTemplateService } from '../design-template.service';
import { DocumentService } from '../document.service';
import { LocalizationService } from '../localization.service';
import { LoggerService } from '../logger.service';
import { ModalService } from '../modal.service';
import { OfflineService } from '../offline.service';
import { SignalRService } from '../signalr.service';
import { UnitService } from '../unit.service';
import { UserSettingsService } from '../user-settings.service';

const maxStates = 51;

export enum Status { Canceled = 'canceled' }
export type LogChange = { modelChange: Change, isHidden: boolean, isDisabled: boolean, allowedValues: number[], disabledValues: number[], min: number, max: number, displayKey: string, title: string, toolTip: string, titleDisplayKey: string, itemsTexts: { [id: number]: UIPropertyTexts } };

export type ICalculationModel = { [property: number]: unknown };

export interface UIPropertyConfigExtended extends IUIPropertyConfig {
    Size: number;
}
export interface BaseCalculateDesignRequestData {
    calculateAll: boolean;
    importingLoadCases: boolean;
    generateReportData: GenerateReportRequest;
    calculateAdvancedBaseplate: boolean;
    calculateLongRunning: boolean;
    isReportCalculation: boolean;
}

export interface ICalculateInternalOptions extends ICalculateInternalOptionsBase {
    generateReportData?: GenerateReportRequest;
}

export function triggerDesignEvent(design: Design, propertyChanges: IPropertyChanges) {
    if (propertyChanges.updatedAllowedValues != null && propertyChanges.updatedAllowedValues.length > 0) {
        design.trigger(DesignEvent.allowedValuesChanged, design, propertyChanges.updatedAllowedValues);
    }

    if (propertyChanges.updatedDisabledValues != null && propertyChanges.updatedDisabledValues.length > 0) {
        design.trigger(DesignEvent.disabledValuesChanged, design, propertyChanges.updatedDisabledValues);
    }

    if (Object.keys(propertyChanges.updatedHidden).length > 0) {
        design.trigger(DesignEvent.hiddenChanged, design);
    }

    if (Object.keys(propertyChanges.updatedDisabled).length > 0) {
        design.trigger(DesignEvent.disabledChanged, design);
    }
}

export function cancelCalculationRequest(design: Design, logger: LoggerServiceBase) {
    if (design.cancellationToken != null) {
        design.cancellationToken.resolve();
        design.cancellationToken = null;

        // get the canceled changes back
        if (design.lastModelChanges != null) {
            design.modelChanges.changes = design.lastModelChanges.concat(design.modelChanges.changes);
            design.lastModelChanges = null;

            design.modelChanges.observe();
        }

        // print
        logger.log('Calculate canceled');
    }
}

export function sanitizePropertyValues(properties: UIProperties) {
    for (const name in properties) {
        const property = properties[name as keyof typeof properties];
        const sanitizedVal = sanitizePropertyValueFromJson(property.Value);
        if (property.Value != sanitizedVal) {
            property.Value = sanitizedVal;
        }
    }
}

export async function calculateAsyncHelper(
    logger: LoggerService,
    calculateFn: (design: Design, calculateId: string, options: BaseCalculateDesignRequestData) => void,
    design: Design,
    modal: ModalService,
    localization: LocalizationService,
    changeFn?: (design: Design) => void,
    options?: ICalculateInternalOptions): Promise<ICalculationResult> {
    changeFn?.(design);

    setCalculationLoader(design, modal, localization, options);

    design.setPendingCalculation(true);
    const calculateId = design.calculateId = design.guid.new();

    const calculateDefer = design.calculateDefer;

    options = {
        calculateAll: false,
        forceCalculation: false,
        importingLoadCases: false,
        generateReportData: null,
        calculateAdvancedBaseplate: false,
        calculateLongRunning: false,
        ...options
    };
    const { calculateAll, forceCalculation, generateReportData, calculateAdvancedBaseplate, importingLoadCases, calculateLongRunning } = options;

    design.modelChanges.observe();

    // trigger event that might change some data before we do the calculation
    design.trigger(DesignEvent.beforeCalculate, design, design.modelChanges.changes);

    design.modelChanges.observe();
    if (options.changes != null) {
        design.modelChanges.changes.push(...options.changes);
    }

    cancelCalculationRequest(design, logger);

    const anyModelChanges = design.modelChanges.changes != null && design.modelChanges.changes.length > 0;

    if (anyModelChanges) {
        for (const change of design.modelChanges.changes) {
            design.usageCounter.trackModelUIProperyChanged(change.name as any as number);
        }
    }

    if (anyModelChanges || calculateAll && design.designData.calculateAllData == null || forceCalculation || generateReportData || calculateAdvancedBaseplate) {
        calculateFn(design, calculateId, { calculateAll, importingLoadCases, generateReportData, calculateAdvancedBaseplate, calculateLongRunning, isReportCalculation: false });
    }
    else {
        // remove loading flag
        if (design.calculateId == calculateId) {
            design.loading = false;
            design.setPendingCalculation(false);

            // resolve
            design.calculateDefer.resolve({ design, calculationCanceled: true, messagesClosed: Promise.resolve(), reportData: null });
            design.calculateDefer = new Deferred<ICalculationResult>();
        }
    }

    try {
        return await calculateDefer.promise;
    }
    finally {
        modal.loadingCustomClose();
        design.trigger(DesignEvent.afterCalculation, design, design.modelChanges.changes);
    }
}

export function publish(documentService: DocumentService, designTemplateService: DesignTemplateService, design: Design) {
    design.processDesignClose(false);
    if (!design.isTemplate) {
        return documentService.publish(design.id);
    }
    else {
        const hasAnchorName = design.anchorType != null && design.anchorType.name != null && design.anchorSize != null && design.anchorSize.name != null;
        const anchorName = hasAnchorName ? design.anchorType.name + ' ' + design.anchorSize.name : null;

        return designTemplateService.update({
            designTemplateDocumentId: design.templateId,
            designTypeId: design.isC2C ? DesignType.Concrete2Concrete : design.designData.projectDesign.ProjectDesignType,
            designStandardId: design.isC2C ? design.designData.projectDesignC2C.options.designStandard : design.designData.projectDesign.Options.DesignStandard,
            regionId: design.isC2C ? design.designData.projectDesignC2C.options.regionId : design.designData.projectDesign.Options.RegionId,
            templateName: design.templateName,
            anchorName: anchorName,
            approvalNumber: design.approvalNumber,
            projectDesign: JSON.stringify(design.isC2C ? design.designData.projectDesignC2C : design.designData.projectDesign),
            templateFolderId: design?.templateFolderId
        });
    }
}

export function updatePendingCalculationResult(design: Design, data: ICalculationResultEntity): void {
    design.designData.pendingCalculationResult = data != null
        ? { ...data }
        : null;
}

export function afterCalculation(design: Design, calculateAll: boolean, calculateAdvancedBaseplate: boolean, logger: LoggerService) {
    design.usageCounter.CalculationsTriggered++;

    // print changes
    if (environment.isLogEnabled && !calculateAll && !calculateAdvancedBaseplate && design.modelChanges.changes != null && design.modelChanges.changes.length > 0) {
        logger.logGroup(new LogMessage({
            message: 'Calculate'
        }), design.modelChanges.changes.map((change) => {
            const metaData = PropertyMetaData.getById(parseInt(change.name, 10) as UIPropertyId);

            return new LogMessage({
                message: (metaData != null ? metaData.name : change.name) + ': %o => %o',
                args: [trim(change.oldValue), trim(change.newValue)]
            });
        }));
    }

    // clear model changes
    design.lastModelChanges = design.modelChanges.changes.slice();  // we might need them if we cancel the request
    design.modelChanges.clear();
}

export function catchCalculationException(err: any, design: Design, isRequestCanceled: boolean, calculateId: string, logger: LoggerService) {
    // is request canceled
    if (isRequestCanceled) {
        return Status.Canceled;
    }

    // if request was canceled don't reject the promise since a new request was started
    logger.logServiceError(err, 'CalculationService', 'CalculateDesign');

    // reload state if we are not changing the page (babylon breaks if you load a texture and dispose the engine before the texture is loaded)
    if (SignalRService.getStatusCode(err) != 401) {
        design.reloadState();
    }

    // reject calculate promise
    if (design.calculateId == calculateId) {
        design.calculateDefer.reject(err);
        design.calculateDefer = new Deferred<ICalculationResult>();
    }

    // must be simple return so we get to the last .then which acts as finally with argument
    return err;
}

export function afterCalculationUpdates(
    updateCalculationDataFn: (
        design: Design,
        data: any,
        calculationLanguage: string,
        calculationType: CalculationType,
        messagesClosedDeferred?: Deferred<void>,
        disableCalcMessages?: boolean,
        keepMissingProperties?: boolean
    ) => { [property: string]: Change },
    userSettings: UserSettingsService,
    localization: LocalizationService,
    logger: LoggerService,
    trackingFn: () => void,
    isRequestCanceled: boolean,
    response: any,
    design: Design,
    calculateId: string,
    selectedLanguage: string) {
    // is request canceled
    if (isRequestCanceled) {
        return Status.Canceled;
    }

    /*
    * If user starts dragging baseplate point before we get calculcation
    * response from server, we have to discard this calculation result
    * otherwise baseplate points will not get updated to correct position.
    * Once user stops dragging point, new calculation will be triggered.
    * DraggablePoint.invalidDraggingPlatePoints is array of current
    * baseplate points users sees on screen while he is dragging atleast
    * one point. This array is empty while no baseplate points are beeing
    * dragged, so it is only safe to update while this array is empty.
    */
    if (DraggablePointInfoPe.invalidDraggingPlatePoints != null && DraggablePointInfoPe.invalidDraggingPlatePoints.length > 0) {
        cancelCalculationRequest(design, logger);
        return Status.Canceled;
    }

    // swap 2d menu for 3d menu
    design.trigger(DesignEvent.beforeUpdate);

    // set data
    const messagesClosedDeferred = new Deferred();

    const modelChanges = updateCalculationDataFn(design, response, selectedLanguage, CalculationType.valueChange, messagesClosedDeferred, null);


    const combinedChanges = design.changes.chainChanges(design.lastModelChanges, modelChanges != null ? Object.values(modelChanges) : null);

    if (combinedChanges != null && combinedChanges.length > 0) {
        // save state if validation was ok
        saveState(userSettings, localization, design, StateChange.server);
    }

    trackingFn();

    // trigger calculate event
    if (modelChanges != null) {
        design.trigger(DesignEvent.calculate, design, Object.values(modelChanges));
    }

    // resolve calculate promise
    if (design.calculateId == calculateId) {
        design.calculateDefer.resolve({ design, calculationCanceled: false, messagesClosed: messagesClosedDeferred.promise, reportData: response.ReportData });
        design.calculateDefer = new Deferred<ICalculationResult>();
    }

    return response;
}

export function removeLoadingFlag(response: any, design: Design, calculateId: string) {
    // remove loading flag if request is not canceled
    if (response != Status.Canceled) {
        design.cancellationToken = null;
        design.lastModelChanges = null;

        if (design.calculateId == calculateId) {
            design.setPendingCalculation(false);
            design.loading = false;
        }
    }
}

export function createOnDocumentServiceAndTrack(
    documentService: DocumentService,
    signalr: SignalRService,
    design: Design,
    projectId: string,
    openType: ProjectOpenType | ProjectOpenTypeC2C,
    canGenerateUniqueName: boolean,
    ignoreConflict: boolean,
    trackingFn: () => void) {
    if (design.isC2C) {
        design.usageCounterC2C.DateAccessed = new Date();
    }
    else {
        design.usageCounter.DateAccessed = new Date();
    }

    return documentService.addDesign(projectId, design, canGenerateUniqueName, ignoreConflict, false, null)
        .then(() => {
            design.projectOpenType = openType;

            if (design.isC2C) {
                signalr.setHubConnectionsC2C();
            }
            else {
                signalr.setHubConnections();
            }

            trackingFn();

            return design;
        });
}

export function updateFromProperties(properties: Properties, modelChanges: any, propertyChanges: any, logger: LoggerService) {
    // print changes
    if (environment.isLogEnabled) {

        // join changes
        let changes: { [propertyId: string]: LogChange } = {};

        // model changes
        for (const modelChangeKey in modelChanges) {
            const propertyId = modelChangeKey as any as number;
            const modelChange = modelChanges[modelChangeKey];

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].modelChange = modelChange;
        }

        // allowed values changes
        for (const propertyId of propertyChanges.updatedAllowedValues) {
            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].allowedValues = properties.get(propertyId).allowedValues;
        }

        // disabled values changes
        for (const propertyId of propertyChanges.updatedDisabledValues) {
            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].disabledValues = properties.get(propertyId).disabledValues;
        }

        // disabled changes
        for (const propertyId of propertyChanges.updatedDisabled) {
            const isDisabled = properties.get(propertyId).disabled;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].isDisabled = isDisabled;
        }

        // hidden changes
        for (const propertyId of propertyChanges.updatedHidden) {
            const isHidden = properties.get(propertyId).hidden;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].isHidden = isHidden;
        }

        // min changes
        for (const propertyId of propertyChanges.updatedMin) {
            const min = properties.get(propertyId).min;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].min = min;
        }

        // max changes
        for (const propertyId of propertyChanges.updatedMin) {
            const max = properties.get(propertyId).max;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].max = max;
        }

        // display key
        for (const propertyId of propertyChanges.updatedDisplayKey) {
            const displayKey = properties.get(propertyId).displayKey;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].displayKey = displayKey;
        }

        // title
        for (const propertyId of propertyChanges.updatedTooltipTitle) {
            const title = properties.get(propertyId).tooltipTitle;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].title = title;
        }

        // toolTip
        for (const propertyId of propertyChanges.updatedTooltip) {
            const toolTip = properties.get(propertyId).tooltip;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].toolTip = toolTip;
        }

        // updatedTitleDisplayKey
        for (const propertyId of propertyChanges.updatedTitleDisplayKey) {
            const titleDisplayKey = properties.get(propertyId).titleDisplayKey;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].titleDisplayKey = titleDisplayKey;
        }

        // updated ItemsTooltip
        for (const propertyId of propertyChanges.updatedItemsTooltip) {
            const itemsTexts = properties.get(propertyId).itemsTexts;

            if (changes[propertyId] == null) {
                changes[propertyId] = createEmptyLogChange();
            }

            changes[propertyId].itemsTexts = itemsTexts;
        }

        // remove empty changes
        changes = Object.fromEntries(Object.entries(changes).filter(([_, change]) => !isEmptyLogChange(change)));

        if (Object.keys(changes).length > 0) {
            logger.logGroup(new LogMessage({
                message: 'Update'
            }), sortBy(Object.entries(changes).map(([propertyId, change]) => {
                const metaData = PropertyMetaData.getById(parseInt(propertyId, 10) as UIPropertyId);
                let message = (metaData != null ? metaData.name : propertyId) + ':';
                const args: any[] = [];

                if (change.modelChange != null) {
                    message += ' %o => %o |';
                    args.push(trim(change.modelChange.oldValue), trim(change.modelChange.newValue));
                }

                if (change.isDisabled != null) {
                    message += ' editable: %o |';
                    args.push(!change.isDisabled);
                }

                if (change.isHidden != null) {
                    message += ' visible: %o |';
                    args.push(!change.isHidden);
                }

                if (change.min !== undefined) {
                    message += ' min: %o |';
                    args.push(change.min);
                }

                if (change.max !== undefined) {
                    message += ' max: %o |';
                    args.push(change.max);
                }

                if (change.displayKey !== undefined) {
                    message += ' displayKey: %o |';
                    args.push(change.displayKey);
                }

                if (change.title !== undefined) {
                    message += ' title: %o |';
                    args.push(change.title);
                }

                if (change.toolTip !== undefined) {
                    message += ' toolTip: %o |';
                    args.push(change.toolTip);
                }

                if (change.titleDisplayKey !== undefined) {
                    message += ' titleDisplayKey: %o |';
                    args.push(change.titleDisplayKey);
                }

                if (change.allowedValues != null) {
                    message += ' allowedValues: %O |';
                    args.push(change.allowedValues);
                }
                if (change.disabledValues != null) {
                    message += ' disabledValues: %O |';
                    args.push(change.disabledValues);
                }

                if (change.itemsTexts != null && Object.keys(change.itemsTexts).length > 0) {
                    message += ' itemsTooltip: %O |';
                    args.push(change.itemsTexts);
                }

                message = message.substring(0, message.length - 2);

                return new LogMessage({
                    message,
                    args
                });
            }), (log) => log.message));
        }
    }
}

export function updatePropertyValues(design: Design, propertyConfigs: UIPropertyConfigExtended[], keepMissingProperties?: boolean): IPropertyChanges {
    const updatedProperties: { [property: number]: IProperty } = {};
    const uiPropertyIds: { [property: number]: boolean } = {};

    const updatedAllowedValues: number[] = [];
    const updatedDisabledValues: number[] = [];
    const updatedDisabled: number[] = [];
    const updatedHidden: number[] = [];
    const updatedMin: number[] = [];
    const updatedMax: number[] = [];
    const updatedDisplayKey: number[] = [];
    const updatedTooltipTitle: number[] = [];
    const updatedTooltip: number[] = [];
    const updatedTitleDisplayKey: number[] = [];
    const updatedItemsTooltip: number[] = [];
    const updatedItemsTooltipTitle: number[] = [];
    const updatedSize: number[] = [];

    propertyConfigs.forEach(propertyConfig => {
        if (propertyConfig != null) {
            uiPropertyIds[propertyConfig.Property] = true;

            let newProperty = updatedProperties[propertyConfig.Property];
            const property = design.properties.get(propertyConfig.Property);



            // FIX MODULARIZATION: remove "any" and fix the type
            const allowedValues: number[] = (propertyConfig as any)['AllowedValues'];
            const disabledValues: number[] = (propertyConfig as any)['DisabledValues'];
            const allowedValuesUniqueId: string = (propertyConfig as any)['AllowedValuesUniqueId'];



            const disabled = propertyConfig.Editable !== true;
            const hidden = propertyConfig.Visible !== true;
            const size = propertyConfig.Size;

            // allowed values
            if (property.allowedValuesUniqueId != allowedValuesUniqueId) {
                newProperty = newProperty || { ...property };
                newProperty.allowedValues = allowedValues;
                newProperty.allowedValuesUniqueId = allowedValuesUniqueId;

                updatedAllowedValues.push(propertyConfig.Property);
            }

            // disabled values
            if (!isEqual(property.disabledValues, disabledValues)) {
                newProperty = newProperty || { ...property };
                newProperty.disabledValues = disabledValues;
            }

            // disabled
            if (property.disabled !== disabled) {
                newProperty = newProperty || { ...property };
                newProperty.disabled = disabled;

                updatedDisabled.push(propertyConfig.Property);
            }

            // hidden
            if (property.hidden !== hidden) {
                newProperty = newProperty || { ...property };
                newProperty.hidden = hidden;

                updatedHidden.push(propertyConfig.Property);
            }

            // size
            if (property.size !== size) {
                newProperty = newProperty || { ...property };
                newProperty.size = size;

                updatedSize.push(propertyConfig.Property);
            }

            // min
            if (property.min !== propertyConfig.MinValue) {
                newProperty = newProperty || { ...property };
                newProperty.min = propertyConfig.MinValue;

                updatedMin.push(propertyConfig.Property);
            }

            // max
            if (property.max !== propertyConfig.MaxValue) {
                newProperty = newProperty || { ...property };
                newProperty.max = propertyConfig.MaxValue;

                updatedMax.push(propertyConfig.Property);
            }

            // display key
            if (property.displayKey !== propertyConfig.Texts.displayKey) {
                newProperty = newProperty || { ...property };
                newProperty.displayKey = propertyConfig.Texts.displayKey;

                updatedDisplayKey.push(propertyConfig.Property);
            }

            // title
            if (property.titleDisplayKey !== propertyConfig.Texts.titleDisplayKey) {
                newProperty = newProperty || { ...property };
                newProperty.titleDisplayKey = propertyConfig.Texts.titleDisplayKey;

                updatedTitleDisplayKey.push(propertyConfig.Property);
            }

            // tooltip title
            if (property.tooltipTitle !== propertyConfig.Texts.tooltipTitle) {
                newProperty = newProperty || { ...property };
                newProperty.tooltipTitle = propertyConfig.Texts.tooltipTitle;

                updatedTooltipTitle.push(propertyConfig.Property);
            }

            // tooltip
            if (property.tooltip !== propertyConfig.Texts.tooltip) {
                newProperty = newProperty || { ...property };
                newProperty.tooltip = propertyConfig.Texts.tooltip;

                updatedTooltip.push(propertyConfig.Property);
            }

            // items tooltip
            if (!isEqual(property.itemsTexts, propertyConfig.ItemsTexts)) {
                newProperty = newProperty || { ...property };
                newProperty.itemsTexts = cloneDeep(propertyConfig.ItemsTexts);

                updatedItemsTooltip.push(propertyConfig.Property);
            }

            // save change
            if (newProperty != null) {
                updatedProperties[propertyConfig.Property] = newProperty;
            }
        }
    });

    // hide properties that were not returned
    if (!keepMissingProperties) {
        for (const key in design.properties.properties) {
            const propertyId = key as any as number;
            const property = design.properties.get(propertyId);

            let newProperty = updatedProperties[propertyId];

            if (!property.hidden && uiPropertyIds[propertyId] === undefined) {
                newProperty = newProperty || { ...property };

                newProperty.hidden = true;
            }

            // save change
            if (newProperty != null) {
                updatedProperties[propertyId] = newProperty;
            }
        }
    }

    // update properties
    design.properties = design.properties.update(updatedProperties);

    return {
        updatedAllowedValues,
        updatedDisabledValues,
        updatedDisabled,
        updatedHidden,
        updatedMin,
        updatedMax,
        updatedTitleDisplayKey,
        updatedTooltipTitle,
        updatedTooltip,
        updatedItemsTooltip,
        updatedItemsTooltipTitle,
        updatedDisplayKey,
        updatedSize
    };
}

/**
 * Change the model without triggering changes.
 * @param fn The function that changes the model.
 */
export function changeModel(model: ICalculationModel, modelChanges: TrackChanges, changesService: ChangesService, fn: (model: ICalculationModel) => ICalculationModel | void) {
    let currentModel = model;
    const oldModel = cloneDeep(model);
    let changes: { [property: string]: Change };

    try {
        const updatedModel = fn(currentModel);
        if (updatedModel !== undefined) {
            currentModel = updatedModel as ICalculationModel;
        }
    }
    finally {
        changes = changesService.getShallowChanges(oldModel, currentModel);

        // set track changes values
        if (Object.keys(changes).length > 0) {
            for (const propertyId in changes) {
                modelChanges.setOriginalProperty(propertyId, cloneDeep(changes[propertyId].newValue));
            }
        }
    }

    return changes;
}

export function updateDesignCodeLists(design: Design, codeLists: ICodeLists, isC2C: boolean, clear?: boolean) {
    if (clear) {
        design.designData.designCodeLists = {};
        design.designData.designCodeListsC2C = {};
    }

    const codeListUpdated: ICodeLists | ICodeListsC2C = {};

    for (const key in codeLists || {}) {
        const codeListKey = key as any as DesignCodeList;

        const codeList = codeLists[codeListKey];

        if (codeList != null && codeList.length > 0) {
            if (isC2C) {
                design.designData.designCodeListsC2C[codeListKey] = codeList as CodeListC2C[];
            } else {
                design.designData.designCodeLists[codeListKey] = codeList as CodeList[];
            }

            codeListUpdated[codeListKey] = codeList;
        }
    }

    return codeListUpdated;
}

export function resetModel(design: Design) {
    changeModel(design.model, design.modelChanges, design.changes, () => {
        // update properties
        design.properties = design.properties.update({});
        return {};
    });
}

export function download(
    apiService: ApiService,
    browser: BrowserService,
    localization: LocalizationService,
    document: DocumentService,
    offline: OfflineService,
    design: Design,
    overwrite?: boolean) {

    const path = overwrite ? design.offlineDesignPath : null;
    return generateBlob(design, apiService, browser, document)
        .then(blob => {
            const filename = generateFileName(design, localization, document, offline);

            return browser.downloadBlob(
                blob,
                filename,
                false,
                false,
                path)
                .then((response) => {
                    // response is null when saving cancelled
                    if (overwrite && response != null) {
                        design.offlineDesignPath = response;
                    }

                    return response;
                });
        });
}

export function generateFileName(design: Design, localization: LocalizationService, document: DocumentService, offline: OfflineService): string {
    if (offline.isOffline) {
        return `${design.designName}.pe`;
    }

    let baseName = '';
    if (!design.isTemplate) {
        const project = document.findProjectByDesignId(design.id);
        baseName = project?.name;
    }
    else {
        baseName = localization.getString('Agito.Hilti.Profis3.Main.TemplateProjectName');
    }

    return `${baseName}_${design.designName}.pe`;
}

export function registerCurrentStateUndoRedoAction(design: Design, action: IUndoRedoAction) {
    // remove undo redo actions with the same name
    design.currentState.undoRedoActions = design.currentState.undoRedoActions.filter(pendingAction => pendingAction.name != action.name);

    design.currentState.undoRedoActions.push(action);
}

export function saveState(userSettings: UserSettingsService, localization: LocalizationService, design: Design, stateChange?: StateChange) {
    const oldState = design.currentState;

    // clear old states
    const index = design.states.findIndex((state) => state === design.currentState);

    if (index >= 0 && index < design.states.length - 1) {
        design.states.splice(index + 1, design.states.length - (index + 1));
    }

    if (design.states.length >= maxStates) {
        design.states.splice(0, design.states.length - maxStates + 1);
    }


    // save state
    design.currentState = {
        properties: design.properties,
        model: cloneDeep(design.model),
        projectDesign: design.designData.projectDesign,
        projectDesignC2C: design.designData.projectDesignC2C,
        fastenerFamiliesC2C: design.fastenerFamilies,
        reportData: design.designData.reportData,
        reportDataC2C: design.designData.reportDataC2C,
        calculationLanguage: design.calculationLanguage,
        calculateAllData: design.designData.calculateAllData,
        undoRedoActions: design.pendingUndoRedoActions,
        decimalSeparator: getNumberDecimalSeparator(localization.numberFormat(), userSettings),
        groupSeparator: getNumberGroupSeparator(localization.numberFormat(), userSettings),
        pendingCalculationResult: design.designData.pendingCalculationResult,
        isReadOnlyDesign: design.isReadOnlyDesign,
    };

    design.states.push(design.currentState);

    design.pendingUndoRedoActions = [];
    design.snapshotStateId = design.guid.new();

    // trigger state changed event
    design.trigger(DesignEvent.stateChanged, design, design.currentState, oldState, stateChange);
}

export function generateBlob(design: Design, apiService: ApiService, browser: BrowserService, document: DocumentService) {
    if (!design.isC2C && design.designData.projectDesign == null ||
        design.isC2C && design.designData.projectDesignC2C == null) {
        throw new Error('ProjectDesign(or C2C) not set.');
    }

    if (design.designName == null || design.designName == '') {
        throw new Error('designName not set.');
    }

    if (!design.isTemplate) {
        const project = document.findProjectByDesignId(design.id);
        if (project == null) {
            throw new Error('Design is not in any projects.');
        }
    }

    if (design.isC2C) {
        return Promise.resolve(browser.base64toBlob(browser.toBase64(design.designData.projectDesignC2C), 'application/json'));
    }
    else {
        const url = `${environment.baseplateApplicationWebServiceUrl}GenerateDesignXml`;
        const data: GenerateDesignXmlRequest = {
            ProjectDesign: design.designData.projectDesign
        };
        const request = new HttpRequest('POST', url, data, {
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/xml'
            })
        });

        return apiService.request<Blob>(request)
            .then(response => response.body);
    }
}

export function saveDesignStateInternal(
    design: Design,
    messagesClosedDeferred: Deferred<void>,
    userSettings: UserSettingsService,
    localization: LocalizationService): ICalculationResult {
    // save state
    saveState(userSettings, localization, design);

    // trigger create design event
    design.trigger(DesignEvent.createDesign);

    return {
        design,
        calculationCanceled: false,
        messagesClosed: messagesClosedDeferred.promise
    } as ICalculationResult;
}

function createEmptyLogChange(): LogChange {
    return {
        modelChange: null,
        isHidden: null,
        isDisabled: null,
        allowedValues: null,
        disabledValues: null,
        min: undefined,
        max: undefined,
        displayKey: undefined,
        title: undefined,
        toolTip: undefined,
        titleDisplayKey: undefined,
        itemsTexts: {}
    };
}

function isEmptyLogChange(logChange: LogChange) {
    return logChange == null || (
        logChange.modelChange == null &&
        logChange.isHidden == null &&
        logChange.isDisabled == null &&
        logChange.allowedValues == null &&
        logChange.disabledValues == null &&
        logChange.min === undefined &&
        logChange.max === undefined &&
        logChange.displayKey === undefined &&
        logChange.title === undefined &&
        logChange.toolTip === undefined &&
        logChange.titleDisplayKey === undefined &&
        !logChange.itemsTexts != null && Object.keys(logChange.itemsTexts).length > 0);
}

function sanitizePropertyValueFromJson(value: any): any {
    if (value == null) {
        return null;
    }

    if (typeof value === 'string') {
        const valueStr = value.trim();
        if (valueStr !== '') {
            const lowerCase = valueStr.toLowerCase();

            // Handle Infinity
            if (lowerCase == '∞' || lowerCase == 'inf' || lowerCase == 'infinity') {
                return Number.POSITIVE_INFINITY;
            }

            if (lowerCase == '-∞' || lowerCase == '-inf' || lowerCase == '-infinity') {
                return Number.NEGATIVE_INFINITY;
            }
        }
    }

    return value;
}

export function loadStateBase(
    unitService: UnitService,
    localizationService: LocalizationService,
    userSettingsService: UserSettingsService,
    design: Design,
    index: number,
    updateModelFn: (design: Design) => void
) {
    const oldState = design.currentState;
    const oldIndex = design.states.findIndex(state => state === design.currentState);
    design.currentState = design.states[index];

    let updatedAllowedValues: number[];
    let updatedHidden: number[];
    let updatedDisabled: number[];

    // update dependent state data on decimal an group separators
    updateStateModelToCurrentSeparator(unitService, localizationService, userSettingsService, oldState);
    updateStateModelToCurrentSeparator(unitService, localizationService, userSettingsService, design.currentState);

    // update design values
    changeModel(design.model, design.modelChanges, design.changes, () => {
        updateModelFn(design);

        // below updates are for both C2C and PE
        const update = design.updateProperties(design.currentState.properties, true);
        updatedAllowedValues = update.updatedAllowedValues;
        updatedHidden = update.updatedHidden;
        updatedDisabled = update.updatedDisabled;

        design.updateIsReadOnlyDesign(design.currentState.isReadOnlyDesign);
        design.calculationLanguage = design.currentState.calculationLanguage;
        design.updateCalculateAllData(design.currentState.calculateAllData);
        design.fastenerFamilies = design.currentState.fastenerFamiliesC2C;
        runUndoRedoActions(design, oldIndex, index);
    });

    if (updatedAllowedValues != null && updatedAllowedValues.length > 0) {
        design.trigger(DesignEvent.allowedValuesChanged, design, updatedAllowedValues);
    }

    if (updatedHidden != null && updatedHidden.length > 0) {
        design.trigger(DesignEvent.hiddenChanged, design);
    }

    if (updatedDisabled != null && updatedDisabled.length > 0) {
        design.trigger(DesignEvent.disabledChanged, design);
    }

    design.modelChanges.clear();

    // trigger state changed event
    design.trigger(DesignEvent.stateChanged, design, design.currentState, oldState);
}

export function isHnaBasedDesignStandard(value: number) {
    switch (value) {
        case DesignStandardEnum.ACI:
        case DesignStandardEnum.CSA:
        case DesignStandardEnum.TW401:
        case DesignStandardEnum.KR:
        case DesignStandardEnum.TH:
        case DesignStandardEnumC2C.ACI:
        case DesignStandardEnumC2C.CSA:
            return true;

        default:
            return false;
    }
}

export function setCalculationLoader(design: Design, modal: ModalService, localization: LocalizationService, options?: ICalculateInternalOptions) {
    if (design.isC2C && design.connectionType == ConnectionType.ConcreteOverlay &&
        options?.forceCalculation && options?.calculateLongRunning) {
        modal.loadingCustomOpen(
            localization.getString('Agito.Hilti.C2C.Loader.Info.ConcreteOverlay'),
            () => { design.resolveCalculation(); design.setPendingCalculation(false); }
        );
    }

    // if optimized anchor plate thickness is enabled + more than 5 loads, show fullscreen loader
    else if (options != null && options.calculateLongRunning) {
        modal.loadingCustomOpen(
            localization.getString('Agito.Hilti.Profis3.Loader.Info.' + (design.designType.id == DesignType.Handrail ? 'Handrail' : 'Concrete')),
            () => { design.resolveCalculation(); design.setPendingCalculation(false); }
        );
    }
    else {
        design.loading = true;
    }
}

export function setDesignFromDocumentDesign(design: Design, documentDesign: IBaseDesign) {
    design.id = documentDesign.id;
    design.createDate = documentDesign.createDate;
    design.designName = documentDesign.designName;
    design.projectId = documentDesign.projectId;
    design.projectName = documentDesign.projectName;
    design.changeDate = documentDesign.changeDate;
    design.locked = documentDesign.locked;
    design.lockedUser = documentDesign.lockedUser;
    design.owner = documentDesign.owner;
}

function runUndoRedoActions(design: Design, oldIndex: number, newIndex: number) {
    if (oldIndex == newIndex) {
        // run pending undo actions
        for (const action of design.pendingUndoRedoActions) {
            action.undo();
        }

        design.pendingUndoRedoActions = [];
    }
    else if (newIndex < oldIndex) {
        // undo
        for (let currentIndex = oldIndex; currentIndex > newIndex; currentIndex--) {
            const state = design.states[currentIndex];

            for (const action of state.undoRedoActions) {
                action.undo();
            }
        }
    }
    else {
        // redo
        for (let currentIndex = oldIndex + 1; currentIndex <= newIndex; currentIndex++) {
            const state = design.states[currentIndex];

            for (const action of state.undoRedoActions) {
                action.redo();
            }
        }
    }
}

function updateStateModelToCurrentSeparator(unit: UnitService, localization: LocalizationService, userSettings: UserSettingsService, state: IDesignState) {
    const currentDecimalSeparator = getNumberDecimalSeparator(localization.numberFormat(), userSettings);
    const currentGroupSeparator = getNumberGroupSeparator(localization.numberFormat(), userSettings);

    if (state.decimalSeparator != currentDecimalSeparator || state.groupSeparator != currentGroupSeparator) {
        for (const key in state.model) {
            state.model[key] = unit.transformWithSeparator(
                state.model[key] as any,
                state.decimalSeparator,
                state.groupSeparator,
                currentDecimalSeparator,
                currentGroupSeparator,
                null,
                parseInt(key, 10));
        }

        state.decimalSeparator = currentDecimalSeparator;
        state.groupSeparator = currentGroupSeparator;
    }
}
