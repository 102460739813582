import { Injectable } from '@angular/core';
import { ModulesService } from './modules.service';

@Injectable({
    providedIn: 'root'
})
export class ModulesDocumentService {
    constructor(
        private modulesService: ModulesService
    ) { }
}
