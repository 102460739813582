import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { ProjectDesignBaseEntity } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { ProjectDesignConcreteEntity } from '@profis-engineering/pe-ui-shared/project-design';
import { environment } from '../../../environments/environment';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { ICheckbotMappingInputProps } from '../import-from-checkbot/import-from-checkbot.component';
import { DocumentService, IDesignListItem } from '../../services/document.service';
import { urlPath } from '../../module-constants';
import { RoutingService } from '../../services/routing.service';
import { GuidService } from '../../services/guid.service';
import { Project } from '../../entities/project';
import { ModalDialogType } from '../add-edit-design/add-edit-design-models';
import { UserSettingsService } from '../../services/user-settings.service';
import { ModulesService } from '../../services/modules.service';
import { DocumentAccessMode, IDesignListItem as IDesignListItemCommon } from '@profis-engineering/pe-ui-common/services/document.common';
import { CodeListService } from '../../services/code-list.service';
import { ProjectCodeList } from '@profis-engineering/pe-ui-shared/enums/project-code-list';
import { Region } from '@profis-engineering/pe-ui-shared/entities/code-lists/region';
import { DesignType } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { DesignStandard } from '@profis-engineering/pe-ui-shared/entities/code-lists/design-standard';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { ButtonEventType } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ComparisonTable } from '../license-comparison-hol/license-comparison-hol.component';
import split from 'lodash-es/split';
import { ProductInformationService } from '../../services/product-information.service';
import { LicenseService } from '../../services/license.service';

interface ICheckbotMappingRequest {
    RegionId?: number;
}

export interface ICheckbotMappingResponse {
    ProjectName: string;
    DesignName: string;
    Mappings: IMapping[];
    Design: ProjectDesignBaseEntity;
}

export interface IMapping {
    Category: Category;
    Title: string;
    Message: string;
    Status: Status;
    IsComplexDesignMapping: boolean;
}

export enum Status {
    Success = 0,
    PartialSuccess,
    Failed,
    None
}

// Do not change order and spelling of the enum values as these are used in tracking.
export enum Category {
    ProfileType = 0,
    ProfileMaterial,
    BaseplateDimensions,
    BaseplateMaterial,
    Loads,
    BaseMaterialDimensions,
    Units,
    DesignStandard,
    AnchorLayout,
    BaseMaterialGrade
}

export interface ICheckbotDesignMetadata {
    ProjectName: string;
    DesignName: string;
    DesignSourceApplicationType: IDesignSourceApplicationType;
    DesignSourceApplicationName: string;
    IOMVersion: number;
}

export enum IDesignSourceApplicationType {
    None = 0,
    CAD,
    FEA
}

@Component({
    selector: 'app-design-from-checkbot',
    templateUrl: './design-from-checkbot.component.html',
    styleUrls: ['./design-from-checkbot.component.scss']
})
export class DesignFromCheckbotComponent implements OnInit {
    private checkbotMappingResponseData: ICheckbotMappingResponse;
    private isNewDesign = false;
    private checkbotDesignMetadata: ICheckbotDesignMetadata;
    private regionId: number;

    constructor(
        private documentService: DocumentService,
        private modalService: ModalService,
        private user: UserService,
        private apiService: ApiService,
        private routingService: RoutingService,
        private guid: GuidService,
        private userSettings: UserSettingsService,
        private modulesService: ModulesService,
        private codeList: CodeListService,
        private featuresVisibilityService: FeatureVisibilityService,
        private commonTrackingService: CommonTrackingService,
        private productInformation: ProductInformationService,
        private license: LicenseService
    ) {
    }

    public ngOnInit(): void {
        if (!this.featuresVisibilityService.isFeatureEnabled("PE_CheckbotEnabled")) {
            return;
        }

        if (this.isStandardUser) {
            this.openLicenseComparisonHol();
        }
        else {
            this.loadMappingData();
        }
    }

    private async loadMappingData() {
        try {
            this.checkbotDesignMetadata = (await this.getCheckbotDesignMetadata()).body;
            await this.setRegionId();
            this.checkbotMappingResponseData = (await this.getMappingData()).body;

            const mapWithComplexDesigns = this.userSettings.settings.application.general.checkbotLvl2.value;
            // Remove complex design mappings if user has not enabled them.
            if(!mapWithComplexDesigns) {
                this.checkbotMappingResponseData.Mappings = this.checkbotMappingResponseData.Mappings.filter(mapping => !mapping.IsComplexDesignMapping);
            }

            if (!this.checkbotDesignMetadata || !this.checkbotMappingResponseData) {
                this.modalService.openAlertError('Checkbot Import Failed', 'No checkbot design to map');
            } else {
                this.openImportCheckbotDesignModal(mapWithComplexDesigns);

                const failedMappingCategories = this.checkbotMappingResponseData.Mappings.filter(mapping => mapping.Status === Status.Failed)?.map(mapping => Category[mapping.Category]).join(', ');
                if(failedMappingCategories) {
                    this.commonTrackingService.trackOnActivity({
                        CheckbotImportFailureReason: failedMappingCategories,
                        CHECKBOT_SOURCE_APPLICATION_TYPE: IDesignSourceApplicationType[this.checkbotDesignMetadata.DesignSourceApplicationType],
                        CHECKBOT_SOURCE_APPLICATION_NAME: this.checkbotDesignMetadata.DesignSourceApplicationName,
                        CHECKBOT_IOM_VERSION: this.checkbotDesignMetadata.IOMVersion.toString()
                      });
                }
            }
        } catch (error) {
            this.modalService.openAlertServiceError({ response: error });
            console.error('Error:', error);
        }
    }

    private async openImportCheckbotDesignModal(mapWithComplexDesigns: boolean) {
        const modalProps: ICheckbotMappingInputProps = {
            mappingResponse: this.checkbotMappingResponseData,
            onConfirm: (async (modal?: ModalInstance<ICheckbotMappingInputProps>) => {
                const isDesignCreated = await this.handleConfirm(mapWithComplexDesigns);
                this.deleteCheckbotDesign();
                return isDesignCreated;
            }),
            onCancel: async () => {
                this.deleteCheckbotDesign();
            },
        };

        this.modalService.openImportFromCheckbot(modalProps);
    }

    private async handleConfirm(mapWithComplexDesigns: boolean): Promise<IDesignListItemCommon> {
        try {
            const project = await this.handleCheckbotProject();
            const design = await this.handleCheckbotDesign(project);

            if (design) {
                const response = await this.documentService.openDesignExclusivePe(design);

                if (response.ProjectDesignType != DesignType.Concrete) {
                    throw new Error('Checkbot: Invalid design');
                }

                this.mapCheckbotDesign(response as ProjectDesignConcreteEntity, mapWithComplexDesigns);
                await this.documentService.updateDesignWithNewContent(
                    design.id,
                    design.projectId,
                    design.designName,
                    response,
                    null,
                    null,
                    true,
                    false,
                    DocumentAccessMode.Update
                );

                //This is needed to fetch updated project design from server
                this.user.changeDesign(null, null);

                this.commonTrackingService.trackOnButtonClick(ButtonEventType.CheckBotImportContinueClicked);
                this.commonTrackingService.trackOnActivity({
                    CHECKBOT_SOURCE_APPLICATION_TYPE: IDesignSourceApplicationType[this.checkbotDesignMetadata.DesignSourceApplicationType],
                    CHECKBOT_SOURCE_APPLICATION_NAME: this.checkbotDesignMetadata.DesignSourceApplicationName,
                    CHECKBOT_IOM_VERSION: this.checkbotDesignMetadata.IOMVersion?.toString()
                });
                await this.navigate(urlPath.main + design.id);

                return design;
            }
            return null;

        } catch (error) {
            this.modalService.openAlertServiceError({ response: error });
            throw error;
        }
    }

    private async handleCheckbotDesign(project: Project): Promise<IDesignListItem | IDesignListItemCommon> {
        const cbDesignName = this.checkbotMappingResponseData.DesignName;
        let design = Object.values(this.documentService.designsFlat)
            .find(x => x.designName === cbDesignName && x.projectId === project.id);

        if (!design) {
            const designInfo = this.modulesService.getDesignInfoForDesignType(DesignType.Concrete, this.userDefaultRegion, null);
            const region = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[])
                .find(x => x.id === this.userDefaultRegion);

            const designStandard = (this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[])
                .find(x => x.id === this.checkbotMappingResponseData.Design.Options.DesignStandard);

            const saveNewDesignModalPromise = this.modalService.openAddEditDesignFromModule({
                selectedModuleDesignInfo: designInfo,
                addEditType: AddEditType.addFromCheckbot,
                design: {
                    designType: DesignType.Concrete,
                    region: region,
                    projectId: project.id,
                    projectName: project.name,
                    isCompanyProject: false,
                    name: this.checkbotDesignMetadata.DesignName,
                    designStandard: designStandard
                },
            });

            await saveNewDesignModalPromise.closed;

            design = Object.values(this.documentService.designsFlat)
                .find(x => x.designName === cbDesignName && x.projectId === project.id);
            this.isNewDesign = design ? true : false;
        }

        return design;
    }

    private mapCheckbotDesign(design: ProjectDesignConcreteEntity, mapWithComplexDesigns: boolean) {
        const mappingData = this.checkbotMappingResponseData.Design as ProjectDesignConcreteEntity;

        design.CreatedWithCheckbot = true;

        if (this.isNewDesign) {
            // Design Options
            // These can be only set while creating a new design
            design.Options.DesignStandard = mappingData.Options.DesignStandard;
            design.Options.RegionId = this.userDefaultRegion;
        }

        // Profile
        design.Profile.ProfileFamilyId = mappingData.Profile.ProfileFamilyId;
        design.Profile.Angle = mappingData.Profile.Angle;
        design.Profile.IsProfileSteelMaterialCustom = mappingData.Profile.IsProfileSteelMaterialCustom;
        design.Profile.SteelTypeId = mappingData.Profile.SteelTypeId;
        design.Profile.CustomProfileSteelMaterial.Name = mappingData.Profile.CustomProfileSteelMaterial?.Name;
        design.Profile.CustomProfileSteelMaterial.Fy = mappingData.Profile.CustomProfileSteelMaterial?.Fy;
        design.Profile.CustomProfileSteelMaterial.Fu = mappingData.Profile.CustomProfileSteelMaterial?.Fu;
        design.Profile.CustomProfileSteelMaterial.Es = mappingData.Profile.CustomProfileSteelMaterial?.Es;
        design.Profile.CustomProfileSteelMaterial.Density = mappingData.Profile.CustomProfileSteelMaterial?.Density;
        design.Profile.CustomProfileSteelMaterial.Poisson = mappingData.Profile.CustomProfileSteelMaterial?.Poisson;
        design.Profile.ProfileSizeId = mappingData.Profile.ProfileSizeId;
        design.Profile.CustomWidth = mappingData.Profile.CustomWidth;
        design.Profile.CustomHeight = mappingData.Profile.CustomHeight;
        design.Profile.CustomThickness = mappingData.Profile.CustomThickness;
        design.Profile.CustomFlangeThickness = mappingData.Profile.CustomFlangeThickness;
        design.IsProfilePresent = mappingData.IsProfilePresent;

        // Loads
        design.Loads.ActiveLoadCombinationId = mappingData.Loads.ActiveLoadCombinationId;
        design.Loads.LoadCombinations = mappingData.Loads.LoadCombinations;
        design.Loads.ForceX = mappingData.Loads.ForceX;
        design.Loads.ForceY = mappingData.Loads.ForceY;
        design.Loads.ForceZ = mappingData.Loads.ForceZ;
        design.Loads.ForceXVariable = mappingData.Loads.ForceXVariable;
        design.Loads.ForceYVariable = mappingData.Loads.ForceYVariable;
        design.Loads.ForceZVariable = mappingData.Loads.ForceZVariable;
        design.Loads.MomentX = mappingData.Loads.MomentX;
        design.Loads.MomentY = mappingData.Loads.MomentY;
        design.Loads.MomentZ = mappingData.Loads.MomentZ;
        design.Loads.MomentXVariable = mappingData.Loads.MomentXVariable;
        design.Loads.MomentYVariable = mappingData.Loads.MomentYVariable;
        design.Loads.MomentZVariable = mappingData.Loads.MomentZVariable;

        // BIM CAD mappings.

        if (!mapWithComplexDesigns) {
            return;
        }

        // Baseplate
        if(mappingData.AnchorPlate.SteelTypeId != 0) {
            design.AnchorPlate.SteelTypeId = mappingData.AnchorPlate.SteelTypeId;
        }
        if(mappingData.AnchorPlate.Thickness && mappingData.AnchorPlate.Thickness != 0) {
            design.AnchorPlate.Thickness = mappingData.AnchorPlate.Thickness;
        }
        if(mappingData.AnchorPlate.PlateShape) {
            design.AnchorPlate.PlateShape = mappingData.AnchorPlate.PlateShape;
        }
        if(mappingData.AnchorPlate.ShapePoints) {
            design.AnchorPlate.ShapePoints = mappingData.AnchorPlate.ShapePoints;
        }
        // Concrete
        design.BaseMaterial.ConcreteGradeId = mappingData.BaseMaterial.ConcreteGradeId;
        design.BaseMaterial.ConcreteGradeType =  mappingData.BaseMaterial.ConcreteGradeType;
        design.BaseMaterial.CustomCompressiveStrength = mappingData.BaseMaterial.CustomCompressiveStrength;
        design.BaseMaterial.IsLightweightConcrete = mappingData.BaseMaterial.IsLightweightConcrete;
        if(mappingData.BaseMaterial.Thickness != 0) {
            design.BaseMaterial.Thickness = mappingData.BaseMaterial.Thickness;
            design.BaseMaterial.EdgeXPositive = mappingData.BaseMaterial.EdgeXPositive;
            design.BaseMaterial.EdgeYNegative = mappingData.BaseMaterial.EdgeYNegative;
            design.BaseMaterial.EdgeXNegative = mappingData.BaseMaterial.EdgeXNegative;
            design.BaseMaterial.EdgeYPositive = mappingData.BaseMaterial.EdgeYPositive;
            design.BaseMaterial.IsEdgeXPositiveReinforced = mappingData.BaseMaterial.IsEdgeXPositiveReinforced;
            design.BaseMaterial.IsEdgeYPositiveReinforced = mappingData.BaseMaterial.IsEdgeYPositiveReinforced;
            design.BaseMaterial.IsEdgeXNegativeReinforced = mappingData.BaseMaterial.IsEdgeXNegativeReinforced;
            design.BaseMaterial.IsEdgeYNegativeReinforced = mappingData.BaseMaterial.IsEdgeYNegativeReinforced;
        }

        // Anchors
        if(mappingData.Anchor.FastenerId) {
            design.Anchor.FastenerId = mappingData.Anchor.FastenerId;
        }
        if(mappingData.Anchor.CustomEmbedmentDepth != 0) {
            design.Anchor.CustomEmbedmentDepth = mappingData.Anchor.CustomEmbedmentDepth;
        }
        if(mappingData.AnchorLayout.AnchorLayoutId) {
            design.AnchorLayout.AnchorLayoutId = mappingData.AnchorLayout.AnchorLayoutId;
        }
        if(mappingData.AnchorLayout.AnchorLayoutPoints.length > 0) {
            design.AnchorLayout.AnchorLayoutPoints = mappingData.AnchorLayout.AnchorLayoutPoints;
        }

        // Complex design(lvl2) mappings.

        // Stiffener plates
        design.Plates = mappingData.Plates;

        // Beams
        design.Beams = mappingData.Beams;

        // Bolts
        design.CheckbotBolts = mappingData.CheckbotBolts;
    }

    private async handleCheckbotProject(): Promise<Project> {
        const cbProjectName = this.checkbotMappingResponseData.ProjectName;
        let project = Object.values(this.documentService.projectsFlat).find(x => x.name === cbProjectName);

        if (!project) {
            project = new Project({
                id: this.guid.new(),
                name: cbProjectName,
                owner: true,
                isCompanyProject: false,
                expanded: true,
                parentId: null,
                changeDate: new Date(),
                createDate: new Date()
            });

            try {
                await this.documentService.saveProject(project, ModalDialogType.project)
            } catch (error) {
                console.error('Error saving project:', error);
                throw error;
            }
        }

        return project;
    }

    private async navigate(path: string) {
        await this.routingService.navigateToUrl(path);
    }

    private async getMappingData(): Promise<HttpResponse<ICheckbotMappingResponse>> {
        const url = `${environment.checkBotIntegrationServiceUrl}CheckbotDesignMappings`;
        const body: ICheckbotMappingRequest = {
            RegionId: this.regionId
        };
        const request = new HttpRequest('POST', url, body);

        return await this.apiService.request<ICheckbotMappingResponse>(request, { supressErrorMessage: true, forceIncludeAuthenticationHeaders: true });
    }

    private async getCheckbotDesignMetadata(): Promise<HttpResponse<ICheckbotDesignMetadata>> {
        const url = `${environment.checkBotIntegrationServiceUrl}CheckbotDesignMetadata`;
        const request = new HttpRequest('GET', url);

        return await this.apiService.request<ICheckbotDesignMetadata>(request, { supressErrorMessage: true, forceIncludeAuthenticationHeaders: true });
    }

    private deleteCheckbotDesign() {
        const url = `${environment.checkBotIntegrationServiceUrl}design`;
        try {
            this.apiService.request(new HttpRequest('DELETE', url), { supressErrorMessage: true, forceIncludeAuthenticationHeaders: true });
        } catch (error) {
            this.modalService.openAlertServiceError({ response: error });
            console.error('Error deleting checkbot design:', error);
        }
    }

    private get isStandardUser() {
        return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
    }

    private get userDefaultRegion() {
        return this.userSettings.settings.application.general.regionId.value;
    }

    private async setRegionId() {
        this.regionId = this.userDefaultRegion;

        const projectId = Object.values(this.documentService.projectsFlat)
            .find(x => x.name === this.checkbotDesignMetadata.ProjectName)?.id;

        if (projectId) {
            const design = Object.values(this.documentService.designsFlat)
                .find(x => x.designName === this.checkbotDesignMetadata.DesignName && x.projectId === projectId);

            if (design) {
                const response = await this.documentService.openDesignExclusivePe(design);
                await this.documentService.publish(design.id);
                this.regionId = response.Options.RegionId;
            }
        }
    }

    private async openLicenseComparisonHol() {
        let requestFailed: boolean;
        let comparisonData: ComparisonTable;
        let holLicenseLanguageId = this.userSettings.getLanguage().id;
        const userRegion = this.userSettings.getRegionByCountryCode(this.user.authentication.country);
        // For trial license we use old popup where we show when license will expire.
        if (userRegion != null) {
            const licenseComparisonPageLink = this.productInformation.regionLinksForUser(userRegion.id)?.LicenseComparisonPageLink;
            if (licenseComparisonPageLink != undefined && licenseComparisonPageLink.trim() != '') {
                try {
                    /*
                    * BUDQBP-32961:
                    * The language of the license page is selected based on the region and language mapping we received from the HOL team.
                    * We also select a holLicense LanguageId for a specific region, which is used to translate our translation keys into the same language supported by HOL.
                    * If we did not get a mapping for a specific region, then the language list does not exist. In this case, we are trying to get data based on culture
                    */
                    let language: string;
                    if (userRegion.licensePageLanguages != null) {
                        const licensePageLanguage = userRegion.licensePageLanguages.find(x => x.LcId == holLicenseLanguageId) ?? userRegion.licensePageLanguages.find(x => x.DefaultForRegion);
                        language = licensePageLanguage.HolLanguageCode;
                        holLicenseLanguageId = licensePageLanguage.LcId;
                    }
                    else {
                        const cultureLanguage = this.userSettings.getCountryCodeCulture(this.user.authentication.country)
                        language = split(cultureLanguage, '-')[0];
                    }
                    const url = licenseComparisonPageLink.replace("{language}", language);
                    const request = new HttpRequest('GET', url);

                    comparisonData = (await this.apiService.request<ComparisonTable>(request, { supressErrorMessage: true, forceIncludeAuthenticationHeaders: true })).body;
                }
                catch (error) {
                    console.warn(error);
                    requestFailed = true;
                }
            }
        }

        if (!requestFailed && comparisonData != null) {
            this.modalService.openLicenseComparisonHol(comparisonData, holLicenseLanguageId, true);
        }
        else if (this.license?.displayTrialInfo) {
            this.modalService.openTrialBanner();
        }
        else {
            this.modalService.openLicenseComparison(true);
        }
    }
}