import {
    DesignExternalMetaData as DesignExternalMetaDataCommon
} from '@profis-engineering/pe-ui-common/services/document.common';
import { BaseDesign } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design/base-design';
import {
    DesignMethod
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import { AdvancedCalculationType, HandrailConnectionType } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { Design } from './design';

/**
* External design meta-data constructor.
*/
export interface IDesignExternalMetaDataConstructor {
    id?: string;
    name?: string;
    region?: number;
    standard?: number;
    designMethod?: number;
    productName?: string;
    designType?: number;
    approvalNumber?: string;
    calculationType?: number;
    handrailConnectionType?: number;
}

/**
 * Holding information about design meta-data that is duplicated in the document service meta data, for efficient (fast) access.
 */
export class DesignExternalMetaData implements DesignExternalMetaDataCommon {
    public id: string;
    public name: string;
    public region: number;
    public standard: number;
    public designMethod: number;
    public productName: string;
    public designType: number;
    public approvalNumber: string;
    public calculationType: number;
    public handrailConnectionType: number;

    public constructor(designExternalMetaDataConstructor?: IDesignExternalMetaDataConstructor) {
        if (designExternalMetaDataConstructor != undefined && designExternalMetaDataConstructor != null) {
            this.id = designExternalMetaDataConstructor.id;
            this.name = designExternalMetaDataConstructor.name;
            this.region = designExternalMetaDataConstructor.region;
            this.standard = designExternalMetaDataConstructor.standard;
            this.designMethod = designExternalMetaDataConstructor.designMethod;
            this.productName = designExternalMetaDataConstructor.productName;
            this.approvalNumber = designExternalMetaDataConstructor.approvalNumber;
            this.calculationType = designExternalMetaDataConstructor.calculationType;
            this.handrailConnectionType = designExternalMetaDataConstructor.handrailConnectionType;

            this.designType = designExternalMetaDataConstructor.designType;
        }
    }

    // static

    /**
     * Read the design meta-data from design entity for storage in document service
     * @param design
     */

    public static getMetaDataFromDesign(design: Design): DesignExternalMetaData {
        const ret: DesignExternalMetaData = new DesignExternalMetaData();

        // MODULARIZATION
        let productName;
        if (design.isC2C) {
            productName = design.productNameC2C;
        }
        else {
            productName = design.anchorType?.name != null && design.anchorSize?.name != null
                ? design.anchorType.name + ' ' + design.anchorSize.name
                : null;
        }

        ret.designMethod = design.isC2C ? DesignMethod.None : design.designMethodGroup.id;
        ret.calculationType = design.isC2C ? AdvancedCalculationType.Unknown : design.calculationType;
        ret.handrailConnectionType = design.isC2C ? HandrailConnectionType.Unknown : design.handrailConnectionType;
        // MODULARIZATION

        ret.id = design.id;
        ret.name = design.designName;
        ret.region = design.region.id;
        ret.standard = design.designStandardId;
        ret.productName = productName;
        ret.designType = design.designType.id;
        ret.approvalNumber = design.approvalNumber;

        return ret;
    }

    /**
     * Read the design meta-data from deckingDesign entity for storage in document service
     */
    public static getMetaDataFromDeckingDesign(deckingProject: BaseDesign): DesignExternalMetaData {
        const metaData: DesignExternalMetaData = new DesignExternalMetaData();
        metaData.id = deckingProject.id;
        metaData.name = deckingProject.name;
        metaData.region = deckingProject.settings.region.id;
        metaData.standard = 0;
        metaData.designMethod = deckingProject.settings.designMethod.id;
        metaData.productName = '';
        metaData.designType = 106;
        metaData.approvalNumber = '';
        return metaData;
    }
}
